import {
  DateRangeTypes,
  IActivityLogFilters,
  IActivityLogReceivedData,
} from 'src/app/interfaces/IActivityLog';
import { ISortingOptions } from 'src/app/interfaces/ICommon';
import {
  getActionFactory,
  getAsyncActionFactory,
} from '../../core/redux/utils/action-class-factory';
import { IOption } from '../groups/interfaces';

const createAction = getActionFactory('ACTIVITY_LOG');
const createAsyncAction = getAsyncActionFactory('ACTIVITY_LOG');

/* ----------------- async actions ----------------- */
export const FetchFilteredActivityLog = createAsyncAction<
  { toast?: Function },
  IActivityLogReceivedData
>('FETCH_FILTERED_ACTIVITY_LOG');

/* ----------------- sync actions ------------------ */
export const SetAppliedFilters = createAction<IActivityLogFilters>(
  'SET_ACTIVITY_LOG_APPLIED_FILTERS'
);

export const ShowActivityLogFilterDrawer = createAction<boolean>(
  'SHOW_ACTIVITY_LOG_FILTER_DRAWER'
);

export const HideActivityLogFilterDrawer = createAction<boolean>(
  'HIDE_ACTIVITY_LOG_FILTER_DRAWER'
);

export const HideErrorBanner = createAction('HIDE_ERROR_BANNER');

/* ----------------- event filter drawer ----------------- */
export const SetSelectedUsers = createAction<IOption[]>('SET_SELECTED_USERS');

export const SetInitialDateTimeRange = createAction<{
  initialFetchedRange: DateRangeTypes;
}>('SET_INITIAL_DATE_TIME_RANGE_FILTER_DRAWER');

export const SetUserSelectedDateRange = createAction<{
  userSelectedDateRange: DateRangeTypes;
}>('USER_SELECTED_DATE_TIME_RANGE_FILTER_DRAWER');

export const SetUserSelectedDateRangeReset = createAction<{
  userSelectedDateRange: DateRangeTypes;
}>('USER_SELECTED_DATE_TIME_RANGE_FILTER_DRAWER_RESET');

export const SetActivityLogRequestDate = createAction<string>(
  'SET_LOGS_REQUEST_DATE'
);

export const ResetAllFilters = createAction('RESET_ALL_FILTERS');

export const SetEventSubTypesFilter = createAction<{
  userSelectedEventSubTypes: string[];
}>('SET_EVENT_SUBTYPES_FILTER');

export const FetchFilteredEventSubTypes = createAction<string>(
  'FETCH_FILTERED_EVENT_SUBTYPES'
);

export const SetIsUserTouchedDateRangeFilter = createAction<string>(
  'SET_IS_USER_TOUCHED_DATE_RANGE_FILTER'
);

export const SetUserStateToInitialState = createAction<{
  userSelectedDateRange: DateRangeTypes;
  userSelectedEventSubTypes: string[];
  userSelectedUsers: string[];
}>('SET_USER_STATE_FILTERS_TO_INITIAL_STATE_FILTERS');

export const SetActivityLogTableSorting = createAction<ISortingOptions | null>(
  'SET_ACTIVITY_LOG_TABLE_SORTING'
);

export const SetSearchField = createAction<string>('SET_SEARCH_FIELD');
