import { phanesAPIClient } from '../api/clients';
import { ComponentType } from '../enums/ProvisioningFieldTypes';
import { ProvisioningStatus } from '../enums/ProvisioningStatus';
import {
  IEntitlementInstances,
  IProductInstance,
  IProvisioningMetaData,
  IProvisioningQuestion,
} from '../interfaces/ICustomersLists';
import { IGenericTagProps } from '../interfaces/IProductGenericTag';
import {
  IProductTransformed,
  IProductDTO,
  IProductListErrorResponse,
  IProductListSuccessResponse,
  IProductTransformedData,
  IProductObjectTransformed,
  IConvertedProvisioningMetadata,
} from '../interfaces/IProductList';
import {
  Activated,
  CISCODUOPRODUCT,
  CISCOTESTPRODUCT,
  External,
  InActive,
  Legacy,
  Provisioned,
  Trial,
} from '../utils/constant';

function getProvisioningQuestionsMetaData(
  provisioningQuestions: IProvisioningQuestion[],
  provisioningMetadata: IProvisioningMetaData[]
): IConvertedProvisioningMetadata[] {
  if (provisioningQuestions.length > 0) {
    return provisioningQuestions.map((provisioningQuestion) => {
      let questionValue = '';
      if (
        provisioningQuestion.type === ComponentType.DROP_DOWN &&
        provisioningQuestion.options.length === 1
      ) {
        questionValue = provisioningQuestion.options[0].value;
      }
      return {
        name: provisioningQuestion.name,
        value: questionValue,
      };
    });
  } else {
    return provisioningMetadata;
  }
}

export const getProductFlags = (
  product: IProductInstance,
  isProductDeleted: boolean = false,
  isOrgTrial: boolean = false
): IGenericTagProps[] => {
  const productTags: IGenericTagProps[] = [];
  const conditionsAndTags = [
    { condition: product.is_trial || isOrgTrial, tag: Trial },
    { condition: product.isLegacy, tag: Legacy },
    { condition: !product.isActive || isProductDeleted, tag: InActive },
    {
      condition:
        product.isActive &&
        !isProductDeleted &&
        ![ProvisioningStatus.IN_PROGRESS, ProvisioningStatus.ERROR].includes(
          product.provisioning_status as ProvisioningStatus
        ) &&
        !(product.isExternal || product.isLegacy),
      tag: Activated,
    },
    {
      condition:
        product.isActive &&
        !isProductDeleted &&
        [ProvisioningStatus.IN_PROGRESS, ProvisioningStatus.ERROR].includes(
          product.provisioning_status as ProvisioningStatus
        ),
      tag: Provisioned,
    },
    { condition: product.isExternal, tag: External },
  ];
  conditionsAndTags.forEach(({ condition, tag }) => {
    if (condition) {
      productTags.push(tag);
    }
  });
  return productTags;
};

function getProductObject(
  product: IProductInstance
): IProductObjectTransformed {
  return {
    productId: product?.ID,
    activationCode: product?.activationCode,
    instanceId: product?.external_tenant_id,
    name: product?.product.name,
    rawProductName: product?.product.name,
    provisionStatus: product?.provisioning_status,
    region: product?.region,
    isTrial: product?.is_trial ?? false,
    isActive: product?.isActive ?? true,
    isHidden: product?.isHidden ?? false,
    isLegacy: product?.isLegacy ?? false,
    isUseExistingTenantSet: product?.is_use_existing_tenant_set ?? false,
    isEA: product?.isEA ?? false,
    productAllowedRegions: product?.product?.product_allowed_regions,
    initialAdmin: product?.initialAdmin ?? '',
    productTags: getProductFlags(product),
    provisioningQuestionsMetaData: getProvisioningQuestionsMetaData(
      product?.product?.provisioning_questions ?? [],
      product?.product?.provisioning_metadata ?? []
    ),
    provisioningQuestions: product?.product?.provisioning_questions ?? [],
    isOrgEntitledProductCard: Object.prototype.hasOwnProperty.call(
      product,
      'org_entitlement_instances'
    ),
  };
}

function getEntitlements(
  product: IProductInstance,
  unifiedOnboardingIIFeatureFlagExist: boolean = false
): IEntitlementInstances[] {
  let productEntitlements =
    product?.org_entitlement_instances ?? product.entitlement_instances;

  if (
    product.provisioning_status === 'NEW' ||
    product.provisioning_status === 'ACTION_REQUIRED'
  ) {
    productEntitlements = productEntitlements.filter((productEntitlement) => {
      return !productEntitlement?.Deleted;
    });
  }
  const orgEntitlementInstances = productEntitlements.filter(
    (productEntitlement) => {
      return (
        productEntitlement?.entitlement?.description !== '' ||
        (unifiedOnboardingIIFeatureFlagExist &&
          productEntitlement?.entitlement.is_service &&
          product.is_use_existing_tenant_set)
      );
    }
  );
  if (
    Object.prototype.hasOwnProperty.call(
      product,
      'org_entitlement_instances'
    ) ||
    orgEntitlementInstances.length > 0
  ) {
    return orgEntitlementInstances;
  }

  return productEntitlements;
}

function getProductName(
  productObject: IProductObjectTransformed,
  iEntitlementInstance: IEntitlementInstances,
  subUiFeatureFlagExist: boolean = false
): string {
  if (subUiFeatureFlagExist && iEntitlementInstance?.entitlement?.description) {
    return productObject.isOrgEntitledProductCard
      ? iEntitlementInstance?.entitlement?.description
      : `${productObject?.name} ${iEntitlementInstance?.entitlement?.description}`;
  }
  return productObject?.name;
}

function getDifferentTilesData(
  productEntitlements: IEntitlementInstances[],
  productObject: IProductObjectTransformed,
  product: IProductInstance,
  subUiFeatureFlagExist: boolean = false,
  unifiedOnboardingFeatureFlagExist: boolean = false,
  unifiedOnboardingIIFeatureFlagExist: boolean = false
): IProductTransformed[] {
  const finalProductObject: IProductTransformed[] = [];
  for (const productEntitlement of productEntitlements) {
    const isProductDeleted = Boolean(productEntitlement.Deleted);
    const isOrgTrial = Boolean(productEntitlement.is_trial);
    finalProductObject.push({
      ...productObject,
      ...getSubscriptionData(
        productEntitlement,
        productObject,
        subUiFeatureFlagExist,
        unifiedOnboardingFeatureFlagExist,
        unifiedOnboardingIIFeatureFlagExist
      ),
      productTags:
        isProductDeleted || isOrgTrial
          ? getProductFlags(product, isProductDeleted, isOrgTrial)
          : productObject.productTags,
    });
  }
  return finalProductObject;
}
/* pending products */
function getSubscriptionData(
  productEntitlement: IEntitlementInstances,
  productObject: IProductObjectTransformed,
  subUiFeatureFlagExist: boolean = false,
  unifiedOnboardingFeatureFlagExist: boolean = false,
  unifiedOnboardingIIFeatureFlagExist: boolean = false
): IProductTransformed {
  const subscriptionData =
    productObject.isTrial || productEntitlement.is_trial
      ? productEntitlement?.trial
      : productEntitlement?.subscription;
  return {
    ...productObject,
    name: getProductName(
      productObject,
      productEntitlement,
      subUiFeatureFlagExist
    ),
    isService: productEntitlement?.entitlement?.is_service ?? false,
    seat: productEntitlement?.quantity ?? '-',
    tier: productEntitlement?.entitlement?.value ?? '',
    productDescription: productEntitlement?.entitlement?.description || '',
    subscriptionId: subscriptionData?.externalId || '-',
    startDate:
      (productObject.isTrial
        ? subscriptionData.trial_start_date
        : subscriptionData.requested_start_date) ?? '',
    expiryDate:
      (productObject.isTrial
        ? subscriptionData.trial_end_date
        : subscriptionData.subscription_end_date) ?? '',
    isExternal: subscriptionData.is_external ?? false,
    productName: subscriptionData.product_name ?? '',
    canProvision: subscriptionData.is_provisionable ?? false,
  };
}

function getFinalProductObject(
  product: IProductInstance,
  productEntitlements: IEntitlementInstances[],
  productObj: IProductObjectTransformed,
  subUiFeatureFlagExist: boolean = false,
  unifiedOnboardingFeatureFlagExist: boolean = false,
  unifiedOnboardingIIFeatureFlagExist: boolean = false
): IProductTransformed[] {
  let finalProductObject = [];
  if (
    subUiFeatureFlagExist &&
    productObj.isOrgEntitledProductCard &&
    productEntitlements.length >= 1 &&
    product?.provisioning_status === 'FINISHED'
  ) {
    finalProductObject = [
      ...getDifferentTilesData(
        productEntitlements,
        productObj,
        product,
        subUiFeatureFlagExist,
        unifiedOnboardingFeatureFlagExist,
        unifiedOnboardingIIFeatureFlagExist
      ),
    ];
  } else {
    finalProductObject.push({
      ...productObj,
      ...getSubscriptionData(
        productEntitlements[0],
        productObj,
        subUiFeatureFlagExist,
        unifiedOnboardingFeatureFlagExist,
        unifiedOnboardingIIFeatureFlagExist
      ),
    });
  }
  return finalProductObject;
}

function getKeyToPush(
  unifiedOnboardingFeatureFlagExist: boolean,
  productObject: IProductObjectTransformed
): keyof IProductTransformedData {
  if (unifiedOnboardingFeatureFlagExist) {
    return productObject.provisionStatus === 'FINISHED'
      ? 'otherProducts'
      : 'pendingProducts';
  } else {
    return productObject.provisionStatus === 'NEW' ||
      productObject.provisionStatus === 'ACTION_REQUIRED'
      ? 'pendingProducts'
      : 'otherProducts';
  }
}
export const fetchProductList = async (
  enterpriseId: string,
  unifiedOnboardingFeatureFlagExist: boolean = false,
  subUiFeatureFlagExist: boolean = false,
  unifiedOnboardingIIFeatureFlagExist: boolean = false
): Promise<IProductListSuccessResponse | IProductListErrorResponse> => {
  try {
    const response: IProductDTO = await phanesAPIClient.get(
      `/v1/customers?enterprise_id=${enterpriseId}`
    );
    let productTransformedData: IProductTransformedData = {
      allProducts: [],
      pendingProducts: [],
      otherProducts: [],
    };
    const nonHiddenProducts = response.data.filter((productData) => {
      return (
        Object.prototype.hasOwnProperty.call(
          productData,
          'org_entitlement_instances'
        ) || !productData.isHidden
      );
    });
    if (nonHiddenProducts.length > 0) {
      productTransformedData = nonHiddenProducts.reduce(
        (finalData: IProductTransformedData, product) => {
          const productObject: IProductObjectTransformed =
            getProductObject(product);
          const productEntitlements = getEntitlements(
            product,
            unifiedOnboardingIIFeatureFlagExist
          );

          if (productEntitlements.length <= 0) {
            return finalData;
          }
          const finalProductObject = getFinalProductObject(
            product,
            productEntitlements,
            productObject,
            subUiFeatureFlagExist,
            unifiedOnboardingFeatureFlagExist,
            unifiedOnboardingIIFeatureFlagExist
          );

          finalData[
            getKeyToPush(unifiedOnboardingFeatureFlagExist, productObject)
          ].push(...finalProductObject);
          finalData.allProducts.push(...finalProductObject);
          return finalData;
        },
        {
          allProducts: [],
          pendingProducts: [],
          otherProducts: [],
        }
      );
    }

    function filterPendingProducts(
      products: IProductTransformed[]
    ): IProductTransformed[] {
      return products.filter(
        (product) =>
          !product.isService ||
          (product.isService &&
            (product.rawProductName === CISCODUOPRODUCT ||
              product.rawProductName === CISCOTESTPRODUCT))
      );
    }
    productTransformedData.pendingProducts = filterPendingProducts(
      productTransformedData.pendingProducts
    );

    productTransformedData.otherProducts =
      productTransformedData.otherProducts.filter((product) => {
        return !(product.isService && product.provisionStatus === 'FINISHED');
      });
    return {
      isOk: true,
      data: productTransformedData,
      error: null,
    };
  } catch (error) {
    return {
      isOk: false,
      data: null,
      error,
    };
  }
};
