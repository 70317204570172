export const eventTypesSubTypesOptions = [
  {
    label: 'Admin user activity',
    options: [
      { label: 'User login success', value: 'User login success' },
      { label: 'User logout', value: 'User logout' },
    ],
  },
  {
    label: 'Group management',
    options: [
      {
        label: 'Group created',
        value: 'New group added',
      },
      {
        label: 'Group deleted',
        value: 'Group deleted',
      },
      {
        label: 'Users added to group',
        value: 'Users added to group',
      },
      {
        label: 'Users removed from group',
        value: 'Users removed from group',
      },
    ],
  },
  {
    label: 'Role management',
    options: [
      {
        label: 'Role assigned to users',
        value: 'Role assigned to users',
      },
      {
        label: 'Role removed from users',
        value: 'Role removed from users',
      },
      {
        label: 'Role assigned to groups',
        value: 'Role assigned to groups',
      },
      {
        label: 'Role removed from groups',
        value: 'Role removed from groups',
      },
    ],
  },
  {
    label: 'User management',
    options: [
      {
        label: 'User deleted',
        value: 'User deleted',
      },
      {
        label: 'Users invited',
        value: 'Users invited',
      },
    ],
  },
];

export const excludedField = 'userSelectedDateRange';
