import { put, select, takeLatest } from 'redux-saga/effects';
import { IActionWithPayload } from 'src/app/core/redux/interfaces';
import i18n from 'src/app/i18n';
import {
  IActivityLogFilters,
  IFetchEventLogsData,
  IFilters,
  IReduxStateData,
} from 'src/app/interfaces/IActivityLog';
import { ISortingOptions } from 'src/app/interfaces/ICommon';
import {
  FetchFilteredActivityLog,
  SetActivityLogRequestDate,
  SetAppliedFilters,
} from './actions';
import { convertSortToBackendFormat } from './helpers';
import {
  getActivityLogInitialSortingState,
  getAppliedFilters,
  getInitialDrawerFilters,
  getSearchField,
} from './selectors';
import { prometheusAPIClient } from '../../api/clients';
import { getEnterpriseId } from '../enterprises/selectors';

export function* handleSetAppliedFilters(): Generator {
  yield put(FetchFilteredActivityLog.PENDING());
}

export function* handleFetchFilteredActivityLog(
  action: IActionWithPayload<{ toast?: Function }>
): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const appliedFilters = (yield select(
    getAppliedFilters
  )) as IActivityLogFilters;
  const pageNumber = appliedFilters.pageIndex + 1;
  const currentDate = new Date().toISOString();
  const initialFilters = yield select(getInitialDrawerFilters);
  const searchField = yield select(getSearchField);
  const sortingOptions = yield select(getActivityLogInitialSortingState);

  const prepareFilters = (stateData: IReduxStateData): IFilters => {
    const { initialFetchedRange, eventTypes, eventSubTypes, selectedUsers } =
      stateData;

    const filters: IFilters = {
      eventTypes: eventTypes || [],
      eventSubTypes: eventSubTypes || [],
      startDate: initialFetchedRange?.[0] || null,
      endDate: initialFetchedRange?.[1] || null,
      users: selectedUsers || [],
    };
    return filters;
  };

  try {
    const response: IFetchEventLogsData = yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/audit-logs`,
      {
        page: pageNumber,
        limit: appliedFilters.pageSize,
        filters: prepareFilters(initialFilters as IReduxStateData),
        searchTerm: searchField,
        sortBy: convertSortToBackendFormat(sortingOptions as ISortingOptions[]),
      }
    );

    const { currentPage, totalPages, totalRecords, logs } = response.data;

    yield put(SetActivityLogRequestDate(currentDate));

    yield put(
      FetchFilteredActivityLog.SUCCESS({
        currentPage,
        totalPages,
        totalRecords,
        logs,
      })
    );

    if (action.payload?.toast) {
      const toastMessage =
        logs.length === 0
          ? i18n.t('activityLog:nothingFoundToastText')
          : i18n.t('activityLog:successToastText');

      yield action.payload.toast({
        id: `${Date.now()}${crypto.randomUUID()}`,
        title: '',
        description: toastMessage,
        status: logs.length === 0 ? 'info' : 'positive',
        timeout: 5000,
      });
    }
  } catch (error) {
    yield put(FetchFilteredActivityLog.ERROR(error as Error));
  }
  yield put(SetActivityLogRequestDate(currentDate));
}

export function* activityLogSaga(): Generator {
  yield takeLatest(
    FetchFilteredActivityLog.Pending.TYPE,
    handleFetchFilteredActivityLog
  );
  yield takeLatest(SetAppliedFilters.TYPE, handleSetAppliedFilters);
}
