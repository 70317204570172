import { differenceInHours } from 'date-fns';
import { createSelector } from 'reselect';
import {
  IActivityLog,
  IActivityLogState,
} from 'src/app/interfaces/IActivityLog';
import { formatTimestamp, getLogUsersEmails, getSubTypes } from './helpers';
import { IState } from '../../core/redux/interfaces';
import 'core-js/features/set';
import { getAllUsers } from '../common/selectors';

export const getState = (state: IState): IActivityLogState =>
  state.activityLog || {};

export const getAppliedFilters = createSelector(
  [getState],
  ({ appliedFilters }) => appliedFilters
);

export const getActivityLogData = createSelector(
  [getState],
  ({ activityLogData }) => activityLogData
);

export const getIsActivityLogFilterDrawerVisible = createSelector(
  [getState],
  ({ isActivityLogFilterDrawerVisible }) => isActivityLogFilterDrawerVisible
);

export const getInitialDrawerFilters = createSelector(
  [getState],
  ({ initialDrawerFilters }) => initialDrawerFilters
);

export const getUserSelectedDrawerFilters = createSelector(
  [getState],
  ({ userSelectedDrawerFilters }) => userSelectedDrawerFilters
);

export const getIsUserTouchedDateRangeFilter = createSelector(
  [getState],
  ({ hasUserTouchedDateRangeFilter }) => hasUserTouchedDateRangeFilter
);

export const getDrawerSubtypesOptions = createSelector(
  [getState],
  ({ userSelectedDrawerFilters }) =>
    getSubTypes(userSelectedDrawerFilters.userSelectedEventSubTypes)
);

export const getLogUsersOptions = createSelector(
  [getState, getAllUsers],
  ({ userSelectedDrawerFilters }, allUsers) =>
    getLogUsersEmails(allUsers, userSelectedDrawerFilters.userSelectedUsers)
);

export const getActivityLogsRequestDate = createSelector(
  [getState],
  ({ activityLogRequestDate }) => {
    if (!activityLogRequestDate || activityLogRequestDate.length === 0) {
      return {
        date: '',
        time: '',
      };
    }
    const formattedTimestamp = formatTimestamp(activityLogRequestDate);
    const [date, time] = formattedTimestamp.split(' ');
    return {
      date,
      time,
    };
  }
);

export const getActivityLogsRequestDatePast = createSelector(
  [getState, getInitialDrawerFilters],
  ({ activityLogRequestDate, initialDrawerFilters }) => {
    const initialDate = initialDrawerFilters.initialFetchedRange?.[1];

    if (!initialDate) {
      return false;
    }

    const activityLogRequestDatePast = new Date(activityLogRequestDate);
    const initialFetchedRange = new Date(initialDate);

    const hoursDifference = differenceInHours(
      activityLogRequestDatePast,
      initialFetchedRange
    );

    return hoursDifference >= 12;
  }
);

export const getSearchField = createSelector(
  [getState],
  ({ searchField }) => searchField
);

export const getActivityLogInitialSortingState = createSelector(
  [getState],
  ({ sortingOptions }) => sortingOptions
);

export const getSortedActivityLogData = createSelector(
  [getActivityLogData],
  ({ data: { logs } }) => {
    let activityLogData: IActivityLog[];
    if (!logs) {
      activityLogData = [];
    } else {
      activityLogData = logs;
    }
    return activityLogData;
  }
);

export const getIsUserTouchedSelectorsFilter = createSelector(
  [getState],
  ({ hasUserTouchedSelectorsFilter }) => hasUserTouchedSelectorsFilter
);

export const getUserSelectedOptions = createSelector(
  [getState],
  ({ userSelectedDrawerFilters }) => userSelectedDrawerFilters
);
