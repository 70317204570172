import { AuthState } from '@okta/okta-auth-js';
import isEmpty from 'lodash/isEmpty';
import {
  GENERIC_ERROR,
  MIN_TABLE_PAGE_SIZE,
  ENTERPRISE_PRODUCT_TYPE,
  X_TRACE_ID_RESPONSE_HEADER,
  ENTERPRISE_PRODUCT_NAME,
} from './constant';
import { UserRoleType } from '../enums/UserRoles';
import { ISortingOptions } from '../interfaces/ICommon';
import { ITransformedReadProductsResponse } from '../interfaces/ICustomersLists';
import { IDependentOption, IOption } from '../interfaces/IGroups';
import {
  IBundledRole,
  ICustomRole,
  IRbacRole,
  IRole,
  RbacBundledRolesResponseType,
  RbacCustomRolesResponseType,
  RbacRolesResponseType,
} from '../interfaces/IUserRoles';

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank', 'noreferrer');
};

export const capitalizeWord = (word: string): string => {
  const firstLetter = word.charAt(0);
  const firstLetterCap = firstLetter.toUpperCase();
  const remainingLetters = word.slice(1);
  const capitalizedWord = firstLetterCap + remainingLetters.toLowerCase();
  return capitalizedWord;
};

export const getResponseError = (error: any): string => {
  let traceId: string | undefined;
  if (error.response?.headers) {
    traceId = error.response?.headers[X_TRACE_ID_RESPONSE_HEADER];
  }
  return traceId ?? GENERIC_ERROR;
};

export const getResponseErrorStatusCode = (error: any): number => {
  return error.response?.status ?? 0;
};

export const toUpperCase = <T extends string>(item: T): string =>
  item.toUpperCase() as Uppercase<T>;

export const composeFunctions = <T>(...functions: T[]): ((input: T) => T) => {
  return (input: T) => {
    return functions.reduce((acc: T, fn: any) => {
      return fn(acc);
    }, input);
  };
};

// If "pageSize" is "0" - returns "1"
// If "pageSize" is not provided - uses "MIN_TABLE_PAGE_SIZE"
export const getTablePaginationPagesCount: (
  itemsLength: number,
  pageSize?: number
) => number = (itemsLength, pageSize = MIN_TABLE_PAGE_SIZE) => {
  if (pageSize === 0) {
    return 1;
  }

  return Math.ceil(itemsLength / pageSize);
};

// If "pageSize" is "0" - returns "false"
// If "pageSize" is not provided - uses "MIN_TABLE_PAGE_SIZE"
export const getTableEnablePagitation: (
  itemsLength: number,
  pageSize?: number
) => boolean = (itemsLength, pageSize = MIN_TABLE_PAGE_SIZE) =>
  getTablePaginationPagesCount(itemsLength, pageSize) > 1;

export const getCombinedRoles = (
  staticRolesResponse: RbacRolesResponseType,
  customRolesResponse: RbacCustomRolesResponseType,
  bundledRolesResponse: RbacBundledRolesResponseType
): IRole[] => {
  const rbacRoles = staticRolesResponse?.data?.rbacRoles || [];
  const customRoles = customRolesResponse?.data?.customRoles || [];
  const bundledRoles = bundledRolesResponse?.data?.bundledRoles || [];
  // Add a `type` property to differentiate between static roles and custom roles.
  const allRoles = [
    ...rbacRoles.map((role: IRbacRole) => ({
      ...role,
      featureFlags: role.featureFlags || [],
      type: UserRoleType.STATIC,
    })),
    ...customRoles.map((role: ICustomRole) => ({
      ...role,
      featureFlags: role.featureFlags ?? [],
      type: UserRoleType.CUSTOM,
    })),
    ...bundledRoles.map((role: IBundledRole) => ({
      ...role,
      featureFlags: role.featureFlags ?? [],
      type: UserRoleType.BUNDLED,
      product: ENTERPRISE_PRODUCT_NAME,
      productKey: ENTERPRISE_PRODUCT_TYPE,
    })),
  ] as IRole[];

  return allRoles;
};

export const getUpdatedProductInstances = (
  instancesOptions: IDependentOption,
  selectedRole: string
): IDependentOption => {
  const currentInstanceOptions = instancesOptions[selectedRole];

  const isSingle = currentInstanceOptions?.length === 1;

  const updateSelectedInstances = currentInstanceOptions?.map(
    (item: IOption) => ({
      ...item,
      selected: isSingle,
      disabled: isSingle,
    })
  );

  const updatedInstances = currentInstanceOptions
    ? {
        ...instancesOptions,
        [selectedRole]: updateSelectedInstances,
      }
    : instancesOptions;

  return updatedInstances;
};

export const sortTableData = <T>(
  tableData: T[],
  sortingOptions?: ISortingOptions[]
): T[] => {
  const firstSortingOption = sortingOptions?.[0];

  if (
    !firstSortingOption ||
    typeof firstSortingOption.id !== 'string' ||
    typeof firstSortingOption.desc !== 'boolean'
  ) {
    return tableData;
  }

  const { id, desc } = firstSortingOption;
  return [...tableData].sort((prevRow, nextRow) => {
    const prevElement = prevRow[id as keyof T] as string;
    const nextElement = nextRow[id as keyof T] as string;

    if (!prevElement || !nextElement) {
      return 0;
    }

    const prevItem = prevElement.toUpperCase();
    const nextItem = nextElement.toUpperCase();

    if (prevItem < nextItem) {
      return desc ? 1 : -1;
    }

    if (prevItem > nextItem) {
      return desc ? -1 : 1;
    }

    return 0;
  });
};

export const getUserId: (authState: AuthState) => string = (authState) => {
  return (authState?.accessToken?.claims?.uid ?? '') as string;
};

export const getSecurityCloud: (authState: AuthState) => string[] = (
  authState
) => {
  return (authState?.accessToken?.claims['security-cloud'] ?? []) as string[];
};

export const getFormateProductNames = (names: string[]): string => {
  if (names.length === 0) return '';
  if (names.length === 1) return names[0];
  if (names.length === 2) return names.join(' and ');
  return `${names.slice(0, -1).join(', ')} and ${names[names.length - 1]}`;
};

const checkEmptyObject = (
  storageKey: string,
  storage: Storage
): string | null => {
  const foundValue = storage.getItem(storageKey);

  if (foundValue && isEmpty(JSON.parse(foundValue))) return null;

  return foundValue;
};

// SCCv2 uses localStorage and SCCv3 uses sessionStorage for 'okta-token-storage'
export const storageGetItemWrapper = (storageKey: string): string | null => {
  const localStorageValue = checkEmptyObject(storageKey, localStorage);
  const sessionStorageValue = checkEmptyObject(storageKey, sessionStorage);

  return localStorageValue ?? sessionStorageValue;
};

export const isOrgEntitledWithBlockedProvisionCheck = (row: any): boolean => {
  // Check if the product is entitled and have blocked provision check with description
  if (row.original?.isOrgEntitled) {
    return row.original?.entitlementInstances?.some(
      (instance: any): boolean => {
        const hasDescription = instance.entitlement?.description?.trim() !== '';
        const isBlocked = instance.provisionCheck?.is_blocked === true;
        return hasDescription && isBlocked;
      }
    );
  }
  return false;
};

export const hasOrgEntitledProvisionProduct = (
  productsTableData: ITransformedReadProductsResponse[]
): boolean => {
  return productsTableData.some((product) => {
    // Check if the product is entitled and has entitlementInstances
    if (
      product.isOrgEntitled &&
      (product.entitlementInstances ?? []).length > 0
    ) {
      // Check if any entitlementInstance satisfies the conditions
      return (product.entitlementInstances ?? []).some((instance) => {
        const hasDescription = instance.entitlement?.description?.trim() !== '';
        const isBlocked = instance.provisionCheck?.is_blocked === true;
        return hasDescription && isBlocked;
      });
    }
    return false;
  });
};

export const formatAssignedRoles = (
  assignedRoles: Array<{
    roleDisplayName: string;
    roleId: string;
    regionName: string;
    productName: string;
  }>
): string | null => {
  if (assignedRoles.length === 0) {
    return null;
  }
  if (assignedRoles.length === 1) {
    const data = assignedRoles[0];
    return `${data.productName} - ${data.roleDisplayName}, in ${data.regionName} - ${data.roleId}`;
  }
  return `${assignedRoles.length} roles assigned `;
};

export const triggerRoleChangedTokenRefreshEvent = (
  enterpriseId: string
): void => {
  if (enterpriseId !== '') {
    const event = new CustomEvent('ROLE_CHANGED_TOKEN_REFRESHED', {
      detail: { currentEnterpriseId: enterpriseId },
    });
    window.dispatchEvent(event);
  }
};
