import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { filter, isEqual, uniqBy, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { UserRoleType } from 'src/app/enums/UserRoles';
import {
  FEATURE_FLAG_CONSTANTS,
  ENTERPRISE,
  X_PROMETHEUS_TRACE_ID_RESPONSE_HEADER,
  X_TRACE_ID_RESPONSE_HEADER,
  ENTERPRISE_PRODUCT_TYPE,
  ENTERPRISE_PRODUCT_NAME,
} from 'src/app/utils/constant';
import { UpdateGroupMappings } from './actions';
import {
  getChangeSummaryTextKey,
  removeGroupRolesFromRolesOptions,
  removeProductsOptionsWithoutUnassignedRoles,
  sliceTableData,
} from './helpers';
import { IDependentOption } from './interfaces';
import { MAX_MAP_GROUPS_ROWS } from './MapIdentityProvidersGroups/constants';
import { MappingStatuses } from './MapIdentityProvidersGroups/MappingStatus';
import { IOption } from './new-group/interfaces';
import { initialState } from './reducer';
import { IState } from '../../core/redux/interfaces';
import {
  IProductInstance,
  ISortingOptions,
  IProductInstances,
} from '../../interfaces/ICommon';
import {
  IFormattedGroupUser,
  IGroup,
  IGroupRole,
  IRolesRows,
  IGroups,
  IGroupMappingWithName,
  IGroupMapping,
} from '../../interfaces/IGroups';
import {
  composeFunctions,
  getTablePaginationPagesCount,
  sortTableData,
} from '../../utils/shared';
import {
  getProvisionedProductInstances,
  getProductInstanceDisplayName,
  classifyGroupsForKpa,
} from '../common/helpers';
import { getAllProductsInstances, hasFeatureFlags } from '../common/selectors';
import { UpdateIdpGroupMappings } from '../idp-management/actions';
import { getAllIdps, getExternalIdpData } from '../idp-management/selectors';
import {
  getGroupsToRolesRelations,
  getKPAManagedTenantIds,
  getKPAManagedTenants,
} from '../users/selectors';
import { toProductOptions, toRoleOptions } from '../users/utilities';

export const getState = (state: IState): IGroups => state.groups || {};

export const getAllGroups = createSelector([getState], ({ allGroups = [] }) =>
  allGroups.map((group) => {
    return {
      ...group,
      type: group.type || 'Local',
    };
  })
);

export const getIsDeleteGroupModalVisible = createSelector(
  [getState],
  ({ isDeleteGroupModalVisible }) => isDeleteGroupModalVisible
);

export const getActiveDeleteGroupId = createSelector(
  [getState],
  ({ activeDeleteGroupId }) => activeDeleteGroupId
);

export const getGroupToDelete = createSelector(
  [getAllGroups, getActiveDeleteGroupId],
  (allGroups, activeDeleteGroupId) =>
    allGroups.find((group) => group.id === activeDeleteGroupId)
);

export const getAddGroup = createSelector(
  [getState],
  ({ addGroup }) => addGroup.stepper
);

export const getNewGroupName = createSelector(
  [getState],
  ({ addGroup }) => addGroup.newGroupName
);

export const getAddGroupUserOptions = createSelector(
  [getState],
  ({ addGroup }) => addGroup.stepper.usersOptions
);

export const getAddGroupCurrentStep = createSelector(
  [getState],
  ({ addGroup }) => addGroup.stepper.currentStep
);

export const getAddGroupPristineUserOptions = createSelector(
  [getAddGroupUserOptions],
  (usersOptions) =>
    usersOptions.map((option: IOption) => ({
      ...option,
      selected: false,
    }))
);

export const getAddGroupUsersSelectedOptions = createSelector(
  [getState],
  ({ addGroup }) => addGroup.stepper.selectedUsersOptions
);

export const getIsAddGroupDirty = createSelector([getAddGroup], (addGroup) => {
  const initialStateControlValues = {
    name: initialState.addGroup.stepper.name.value,
    description: initialState.addGroup.stepper.description.value,
  };
  const currentStateControlValues = {
    name: addGroup.name.value,
    description: addGroup.description.value,
  };

  return !isEqual(initialStateControlValues, currentStateControlValues);
});

export const getIsDiscardChangesModalVisible = createSelector(
  [getState],
  ({ isDiscardChangesModalVisible }) => isDiscardChangesModalVisible
);

export const getNotificationType = createSelector(
  [getState],
  ({ notificationType }) => notificationType
);

export const hasRemoveGroupPrivilege = createSelector(
  [getState],
  ({ groupUsers }) => !groupUsers?.length
);

export const getIsAddUsersDrawerVisible = createSelector(
  [getState],
  ({ isAddUsersDrawerVisible }) => isAddUsersDrawerVisible
);

export const getIsEditGroupDrawerVisible = createSelector(
  [getState],
  ({ isEditGroupDrawerVisible }) => isEditGroupDrawerVisible
);

export const getIsAssignRolesDrawerVisible = createSelector(
  [getState],
  ({ isAssignRolesDrawerVisible }) => isAssignRolesDrawerVisible
);

export const getGroupsTableSearchField = createSelector(
  [getState],
  ({ groupsTableSearchField }) => groupsTableSearchField
);

export const getFilteredGroupsData = createSelector(
  [getAllGroups, getGroupsTableSearchField],
  (groups, groupsTableSearchField) => {
    const filterDataBySearch = groupsTableSearchField
      ? groups.filter((group) =>
          group.name
            .toLowerCase()
            .includes(groupsTableSearchField.toLowerCase())
        )
      : groups;

    return filterDataBySearch;
  }
);

export const getGroupsTableTableConfig = createSelector(
  [getState],
  ({ groupsPagination }) => groupsPagination
);

export const getGroupsTablePageCount = createSelector(
  [getAllGroups, getGroupsTableTableConfig],
  (groups, { pageSize }) => {
    return getTablePaginationPagesCount(groups.length, pageSize);
  }
);

export const getEditGroup = createSelector(
  [getState],
  ({ editGroup }) => editGroup
);

export const getEditGroupNameDrawer = createSelector(
  [getEditGroup],
  ({ editGroupNameDrawer }) => editGroupNameDrawer
);
export const getAssignRolesTableFromDrawer = createSelector(
  [getEditGroup],
  ({ assignRolesTableFromDrawer }) => assignRolesTableFromDrawer
);
export const getAssignRolesTableSelected = createSelector(
  [getEditGroup],
  ({ assignRolesTableFromDrawer }) =>
    assignRolesTableFromDrawer.map((row) => row.selectedProduct).filter(Boolean)
);
export const getProductOptions = createSelector([getState], ({ allRoles }) =>
  toProductOptions(allRoles)
);
export const getRolesOptions = createSelector([getState], ({ allRoles }) =>
  toRoleOptions(allRoles)
);
export const getSelectedRolesIDs = createSelector(
  [getEditGroup],
  ({ assignRolesTableFromDrawer }) => {
    const selectedRolesValues: string[] = [];

    for (const row of assignRolesTableFromDrawer) {
      if (row.selectedProduct) {
        const selectedProductRoles = row.rolesOptions[row.selectedProduct];
        const rolesIDs = selectedProductRoles
          .filter((role: IOption) => role.selected)
          .map((role: IOption) => role.value);
        selectedRolesValues.push(...rolesIDs);
      }
    }

    return selectedRolesValues;
  }
);

export const getEditedGroupId = createSelector(
  [getState],
  ({ activeEditGroupId }) => activeEditGroupId
);

export const getEditedGroup = createSelector(
  [getAllGroups, getEditedGroupId],
  (allGroups, activeEditGroupId) =>
    allGroups.find((group: IGroup) => group.id === activeEditGroupId)
);

export const getUpdatedGroupName = createSelector(
  [getEditedGroup],
  (group) => group?.name
);

export const getUsersOptionsFromDrawer = createSelector(
  [getEditGroup],
  ({ usersOptionsFromDrawer }) => usersOptionsFromDrawer
);

export const getSelectedUsersOptionsFromDrawer = createSelector(
  [getUsersOptionsFromDrawer],
  (usersOptionsFromDrawer) =>
    usersOptionsFromDrawer.filter((option: CDSOption) => option.selected)
);

export const getPristineUsersOptionsFromDrawer = createSelector(
  [getUsersOptionsFromDrawer],
  (usersOptionsFromDrawer) =>
    usersOptionsFromDrawer.map((option: CDSOption) => ({
      ...option,
      selected: false,
    }))
);

export const getActiveRemoveAssignedUserIds = createSelector(
  [getEditGroup],
  ({ activeRemoveAssignedUsers }) => activeRemoveAssignedUsers
);

export const getActiveRemoveAssignedSingleUserId = createSelector(
  [getEditGroup],
  ({ activeRemoveAssignedSingleUser }) => activeRemoveAssignedSingleUser
);

export const getActiveRemoveRoleId = createSelector(
  [getEditGroup],
  ({ activeRemoveRoleId }) => activeRemoveRoleId
);

export const getRemovedUsersCount = createSelector(
  [getEditGroup],
  ({ removedUsersCount }) => removedUsersCount
);

export const getProducts = createSelector(
  [getAllProductsInstances],
  ({ items }) => {
    const products = items.map(
      ({ productName, productType }: IProductInstance) => ({
        label:
          productType === ENTERPRISE_PRODUCT_TYPE
            ? ENTERPRISE_PRODUCT_NAME
            : productName,
        value: productType,
      })
    );
    return uniqBy(products, 'value');
  }
);

export const getAllRoles = createSelector(
  [getState],
  ({ allRoles }) => allRoles
);

export const getSharedGroupRoles = createSelector(
  [getEditGroup],
  ({ sharedGroupRoles }) => sharedGroupRoles
);

export const getBundledRoles = createSelector([getState], ({ allRoles }) =>
  allRoles.filter((role) => role.type === UserRoleType.BUNDLED)
);

export const getOldGroupRoles = createSelector(
  [getEditGroup],
  ({ groupRoles }) => groupRoles
);

export const getGroupRoles = createSelector(
  [
    getEditGroup,
    getAllProductsInstances,
    (state) => hasFeatureFlags(state, [FEATURE_FLAG_CONSTANTS.BUNDLED_ROLE]),
  ],
  (
    { groupRoles },
    products: IProductInstances,
    useRoleDescriptionForProductInstanceDisplay
  ) => {
    const output = groupRoles.map((groupRole: IGroupRole) => {
      const matchedProducts = filter(
        products.items,
        ({ id }: IProductInstance) => groupRole.tenantId === id
      );

      const isBundledRole = groupRole.type === UserRoleType.BUNDLED;
      const instanceName = isBundledRole
        ? ENTERPRISE
        : matchedProducts
            .map((productInstance: IProductInstance) =>
              getProductInstanceDisplayName(
                productInstance,
                useRoleDescriptionForProductInstanceDisplay
              )
            )
            .join(', ');

      let formattedRole = `${groupRole.product} - ${groupRole.role}`;

      if (groupRole.productKey === ENTERPRISE_PRODUCT_TYPE) {
        formattedRole = `${ENTERPRISE_PRODUCT_NAME} - ${groupRole.role}`;
      }

      if (isBundledRole) {
        formattedRole = groupRole.role;
      }

      return {
        ...groupRole,
        role: formattedRole,
        product: instanceName,
        productName: isBundledRole
          ? ENTERPRISE_PRODUCT_NAME
          : groupRole.product,
      };
    });

    return output;
  }
);

export const getUnassignedRolesOptions = createSelector(
  [getRolesOptions, getGroupRoles],
  (rolesOptions, assignedRoles) =>
    removeGroupRolesFromRolesOptions(rolesOptions, assignedRoles)
);

export const getProductsOptionsWithUnassignedRoles = createSelector(
  [getProductOptions, getUnassignedRolesOptions],
  (productsOptions, unassignedRolesOptions) =>
    removeProductsOptionsWithoutUnassignedRoles(
      productsOptions,
      unassignedRolesOptions
    )
);

export const getRemoveSingleUserById = createSelector(
  [getEditGroup, getState],
  ({ activeRemoveAssignedSingleUser }, { groupUsers }) =>
    groupUsers.find((user) => user.id === activeRemoveAssignedSingleUser)
);

export const getRemoveUserById = createSelector(
  [getState, (ar, id) => id],
  ({ groupUsers }, id) => groupUsers.find((user) => user.id === id)
);

export const getGroupUsers = createSelector(
  [getState],
  ({ groupUsers }) => groupUsers
);

export const getIsRemoveAllUsers = createSelector(
  [getActiveRemoveAssignedUserIds, getState],
  (activeRemoveAssignedUsers, { groupUsers }) =>
    activeRemoveAssignedUsers?.length === groupUsers.length
);

export const getUsersTablePagination = createSelector(
  [getEditGroup],
  ({ usersTablePagination }) => usersTablePagination
);

export const getUsersInitialSortingState = createSelector(
  [getEditGroup],
  ({ usersTable: { sortingOptions } }) => sortingOptions
);

export const getFilteredUsersTableData = createSelector(
  [getGroupUsers, getEditGroup],
  (tableData, { usersTable: { search, sortingOptions } }) => {
    const filterDataBySearch = search
      ? tableData.filter(
          (user) =>
            user.firstname.toLowerCase().includes(search.toLowerCase()) ||
            user.lastname.toLowerCase().includes(search.toLowerCase()) ||
            user.email.toLowerCase().includes(search.toLowerCase())
        )
      : tableData;

    const sortedData = sortTableData<IFormattedGroupUser>(
      filterDataBySearch,
      sortingOptions
    );
    return sortedData;
  }
);

export const getSlicedUsersTableData = createSelector(
  [getFilteredUsersTableData, getUsersTablePagination],
  (filteredUsers, { pageSize, pageIndex }) => {
    const slicedTableData = sliceTableData<IFormattedGroupUser>(
      pageIndex,
      pageSize,
      filteredUsers
    );

    return slicedTableData;
  }
);

export const getUsersTablePageCount = createSelector(
  [getFilteredUsersTableData, getUsersTablePagination],
  (assignedGroupsTableData, { pageSize }) => {
    return getTablePaginationPagesCount(
      assignedGroupsTableData.length,
      pageSize
    );
  }
);

export const getUserTableSelectedRows = createSelector(
  [getEditGroup],
  ({ userTableSelectedRows }) => userTableSelectedRows
);

export const getUsersTableSearch = createSelector(
  [getEditGroup],
  ({ usersTable: { search } }) => search
);

export const getAssignedRolesTablePagination = createSelector(
  [getEditGroup],
  ({ assignedRolesTablePagination }) => assignedRolesTablePagination
);

export const getProductFilterSelectedOption = createSelector(
  [getEditGroup],
  ({ assignRolesTable }) => assignRolesTable.productFilterSelectedOption
);

export const getRegionFilterSelectedOption = createSelector(
  [getEditGroup],
  ({ assignRolesTable }) => assignRolesTable.regionFilterSelectedOption
);

export const getProductFilterOptions = createSelector(
  [getEditGroup, getProductFilterSelectedOption],
  (
    { assignRolesTable: { productFilterOptions } },
    productFilterSelectedOption
  ) => {
    return productFilterSelectedOption
      ? productFilterOptions.map((option) => {
          if (productFilterSelectedOption.value === option.value) {
            return productFilterSelectedOption;
          }
          return option;
        })
      : productFilterOptions;
  }
);

export const getRegionFilterOptions = createSelector(
  [getEditGroup, getRegionFilterSelectedOption],
  (
    { assignRolesTable: { regionFilterOptions } },
    regionFilterSelectedOption
  ) => {
    return regionFilterSelectedOption
      ? regionFilterOptions.map((option) => {
          if (regionFilterSelectedOption.value === option.value) {
            return regionFilterSelectedOption;
          }
          return option;
        })
      : regionFilterOptions;
  }
);

// common result function for two selectors, getOldFilteredAssignedRolesTableData could be removed once TENANT_LVL_ROLES_ASSIGNMENT ff is removed
const resultFuncFilterData = (
  tableData: IGroupRole[],
  {
    assignRolesTable: { search, sortingOptions, typeFilterSelectedOption },
  }: {
    assignRolesTable: {
      search: string;
      sortingOptions: ISortingOptions[];
      typeFilterSelectedOption: IOption | null;
    };
  },
  productFilterSelectedOption: IOption | null,
  regionFilterSelectedOption: IOption | null
): IGroupRole[] => {
  const filterBySearch = (): IGroupRole[] => {
    const formattedSearch = search.toLowerCase();
    const result = search
      ? tableData.filter(
          (role) =>
            role.product.toLowerCase().includes(formattedSearch) ||
            role.role.toLowerCase().includes(formattedSearch)
        )
      : tableData;
    const sortedData = sortTableData<IGroupRole>(result, sortingOptions);

    return sortedData;
  };

  const filterByProduct = (data: IGroupRole[]): IGroupRole[] => {
    const result = productFilterSelectedOption
      ? data.filter((item: IGroupRole) => {
          const searchValue = productFilterSelectedOption.value;
          return searchValue === item.productKey;
        })
      : data;

    return result;
  };

  const filterByRegion = (data: IGroupRole[]): IGroupRole[] => {
    const result = regionFilterSelectedOption
      ? data.filter(({ product }: IGroupRole) => {
          const { label } = regionFilterSelectedOption;
          const productRegion = product.split(' - ').shift();
          const hasMatch = productRegion === label || product.includes(label);
          return hasMatch;
        })
      : data;
    return result;
  };

  const filterByRoleType = (data: IGroupRole[]): IGroupRole[] => {
    const result = typeFilterSelectedOption
      ? data.filter(({ type }: IGroupRole) => {
          return typeFilterSelectedOption.value === type;
        })
      : data;
    return result;
  };

  const composedFunction = composeFunctions(
    filterBySearch,
    filterByProduct,
    filterByRegion,
    filterByRoleType
  ) as Function;

  const output = composedFunction();

  return output;
};

export const getOldFilteredAssignedRolesTableData = createSelector(
  [
    getOldGroupRoles,
    getEditGroup,
    getProductFilterSelectedOption,
    getRegionFilterSelectedOption,
  ],
  resultFuncFilterData
);

export const getFilteredAssignedRolesTableData = createSelector(
  [
    getGroupRoles,
    getEditGroup,
    getProductFilterSelectedOption,
    getRegionFilterSelectedOption,
  ],
  resultFuncFilterData
);

// common result function for two selectors, getOldRemoveRoleById could be removed once TENANT_LVL_ROLES_ASSIGNMENT ff is removed
const resultFuncGetRemoveRole = (
  { activeRemoveRoleId }: { activeRemoveRoleId: null | string },
  filteredAssignedRolesTableData: IGroupRole[]
): IGroupRole | undefined =>
  filteredAssignedRolesTableData.find(
    (role: IGroupRole) => role.id === activeRemoveRoleId
  );

export const getOldRemoveRoleById = createSelector(
  [getEditGroup, getOldFilteredAssignedRolesTableData],
  resultFuncGetRemoveRole
);

export const getRemoveRoleById = createSelector(
  [getEditGroup, getFilteredAssignedRolesTableData],
  resultFuncGetRemoveRole
);

export const getSlicedAssignedRolesTableData = createSelector(
  [getFilteredAssignedRolesTableData, getAssignedRolesTablePagination],
  (filteredRoles, { pageSize, pageIndex }) => {
    const slicedTableData = sliceTableData<IGroupRole>(
      pageIndex,
      pageSize,
      filteredRoles
    );

    return slicedTableData;
  }
);

export const getAssignedRolesTablePageCount = createSelector(
  [getFilteredAssignedRolesTableData, getUsersTablePagination],
  (assignedGroupsTableData, { pageSize }) => {
    return getTablePaginationPagesCount(
      assignedGroupsTableData.length,
      pageSize
    );
  }
);

export const getAssignedRolesTableSearch = createSelector(
  [getEditGroup],
  ({ assignRolesTable }) => assignRolesTable.search
);

export const getAssignedRolesTableSortingOptions = createSelector(
  [getEditGroup],
  ({ assignRolesTable }) => assignRolesTable.sortingOptions
);

export const getEditedGroupState = createSelector(
  [getEditGroup],
  ({ editedGroupState }) => editedGroupState
);

export const getFormFieldsForNewIdpGroup = createSelector(
  [getState],
  ({ mapGroupsStepper }) => mapGroupsStepper.secondStep.createGroupDrawer
);

export const getMapGroupsStepperCurrentStep = createSelector(
  [getState],
  ({ mapGroupsStepper }) => mapGroupsStepper.currentStep
);

export const getIsIdpGroupDrawerVisible = createSelector(
  [getFormFieldsForNewIdpGroup],
  ({ isCreateGroupDrawerVisible }) => isCreateGroupDrawerVisible
);

export const getIsIdpGroupLoading = createSelector(
  [getFormFieldsForNewIdpGroup],
  ({ data }) => data.isIdpGroupLoading
);

export const getMapGroupsStepperState = createSelector(
  [getState],
  ({ mapGroupsStepper }) => mapGroupsStepper
);

export const getMappedGroupRowsData = createSelector(
  [getMapGroupsStepperState],
  ({ secondStep }) => secondStep.rowsData
);

export const getExistingInternalMappingsResponse = createSelector(
  getMapGroupsStepperState,
  ({ existingMappings }) => existingMappings
);

export const getExistingExternalMappingsResponse = createSelector(
  getExternalIdpData,
  (idps) => {
    return idps.reduce<IGroupMapping[]>((mappings, item) => {
      return mappings.concat(
        (item.groupMappings ?? []).map((mapping) => ({
          ...mapping,
          idpId: item.id,
        }))
      );
    }, []);
  }
);

export const getIsSubmittingGroupMappings = (state: IState): boolean => {
  const isSubmittingGroupMappings =
    state.loader.pending.includes(UpdateGroupMappings.Pending.TYPE) ||
    state.loader.pending.includes(UpdateIdpGroupMappings.Pending.TYPE);
  return isSubmittingGroupMappings;
};

export const getUpdateGroupMappingError = createSelector(
  [getMapGroupsStepperState],
  ({ updateGroupMappingsError: error }) => {
    const displayedTraceId =
      error?.response?.headers[X_TRACE_ID_RESPONSE_HEADER] ??
      error?.response?.headers[X_PROMETHEUS_TRACE_ID_RESPONSE_HEADER] ??
      '';
    const errorTraceId =
      error?.response?.status === 500 ? displayedTraceId : '';

    return {
      errorTraceId,
      isError: Boolean(error),
    };
  }
);

export const getIdpGroups = createSelector(
  [getState],
  ({ idpGroups }) => idpGroups.data
);

export const getIdpGroupsError = createSelector(
  [getState],
  ({ idpGroups }) => idpGroups.error
);

export const getIdpGroupsOptions = createSelector([getIdpGroups], (idpGroups) =>
  idpGroups.map((item) => ({
    label: item.name,
    value: item.id,
  }))
);
export const getIdpOptions = createSelector([getAllIdps], (idps) => {
  return (
    idps?.map((idp) => ({
      label: idp.name,
      value: idp.id,
    })) ?? []
  );
});

export const getExistingMappingsWithName = createSelector(
  [
    getExistingInternalMappingsResponse,
    getExistingExternalMappingsResponse,
    getIdpGroups,
    getAllIdps,
  ],
  (internalGroupMappings, externalGroupMappings, internalIdpGroups, idps) => {
    return [
      ...(internalGroupMappings.data?.groupMappings?.map<IGroupMappingWithName>(
        (m) => {
          const group = internalIdpGroups.find((g) => g.id === m.rbacGroupId);
          const idp = idps.find((i) => i.id === m.idpId);

          return {
            idpName: idp?.name,
            idpId: m.idpId,
            idpGroupName: m.idpGroupName,
            sccGroupName: group?.name,
            sccGroupId: m.rbacGroupId,
          };
        }
      ) ?? []),
      ...(externalGroupMappings.map<IGroupMappingWithName>((m) => {
        const group = internalIdpGroups.find((g) => g.id === m.rbacGroupId);
        const idp = idps.find((i) => i.id === m.idpId);

        return {
          idpName: idp?.name,
          idpId: m.idpId,
          idpGroupName: m.idpGroupName,
          sccGroupName: group?.name,
          sccGroupId: m.rbacGroupId,
        };
      }) ?? []),
    ];
  }
);

export const getReviewData = createSelector(
  [getMappedGroupRowsData, getExistingMappingsWithName, getExternalIdpData],
  (rowsData, existingMappings, externalIdps) => {
    return rowsData
      .filter(
        (r) => r.idpGroupName.value && r.sccGroupName.selectedOption?.value
      )
      .map((r, index) => {
        const mappingStatus = r.rowStatus;
        const idpGroupName = r.idpGroupName.value;
        const sccGroupName = r.sccGroupName.selectedOption?.label ?? '';
        const idpName = r.idpName.selectedOption?.label ?? '';
        const idpId = r.idpName.selectedOption?.value ?? '';
        const isExternalIdp = !!externalIdps.find((idp) => idp.id === idpId);

        const initialMapping = existingMappings[index];

        const oldIdpName = initialMapping?.idpName;
        const oldIdpGroupName = initialMapping?.idpGroupName;
        const oldSccGroupName = initialMapping?.sccGroupName;

        return {
          idpId,
          idpName,
          isExternalIdp,
          idpGroupName,
          sccGroupName,
          oldIdpName,
          oldSccGroupName,
          oldIdpGroupName,
          mappingStatus,
          rbacGroupId: r.sccGroupName.selectedOption?.value ?? '',
          changeSummaryKey: getChangeSummaryTextKey(mappingStatus),
        };
      });
  }
);

export const getIsGroupMappingDirty = createSelector(
  [getMappedGroupRowsData],
  (rowsData) => {
    return rowsData.some((r) => r.rowStatus !== MappingStatuses.Unchanged);
  }
);

export const getExistingMappings = createSelector(
  [getExistingInternalMappingsResponse, getExistingExternalMappingsResponse],
  ({ data }, externalIdpGroupMappings) => [
    ...(data?.groupMappings?.map((mapping) => ({
      ...mapping,
      idpId: data.idpId,
    })) ?? []),
    ...externalIdpGroupMappings,
  ]
);

export const getStepperMode = createSelector(
  getExistingMappings,
  (groupMappings) => {
    return groupMappings.length ? 'edit' : 'create';
  }
);

export const getGroupsOptions = createSelector([getAllGroups], (allGroups) =>
  allGroups.map((item) => ({
    label: item.name,
    value: item.id,
    selected: false,
  }))
);

export const getIsMappedGroupRowsLimit = createSelector(
  [getMappedGroupRowsData],
  (rowsData) => rowsData.length > MAX_MAP_GROUPS_ROWS
);

export const getAssignRolesDrawer = createSelector(
  [getEditGroup],
  ({ assignRolesDrawer }) => assignRolesDrawer
);

export const getAssignRolesDrawerTable = createSelector(
  [getAssignRolesDrawer],
  ({ assignRolesTable }) => assignRolesTable
);

export const getRolesWithProvisionedInstances = createSelector(
  [getAllRoles, getAllProductsInstances],
  (allRoles, { items: allProductsInstances }) => {
    const resultArr: IDependentOption = [];

    const productTypes = Array.from(
      new Set(allProductsInstances.map((instance) => instance.productType))
    );

    productTypes.forEach((type) => {
      const matchedRoles = allRoles.filter((role) => role.productKey === type);

      const instancesWithRoleType = allProductsInstances.filter((instance) => {
        return instance.productType === type;
      });
      const provisionedInstancesWithRoleType = getProvisionedProductInstances(
        instancesWithRoleType
      );

      if (matchedRoles.length > 0 && provisionedInstancesWithRoleType.length) {
        matchedRoles.forEach((item) => {
          resultArr.push({
            ...item,
            productAndRole: `${item?.product} - ${item?.roleDisplayName}`,
            instances: provisionedInstancesWithRoleType,
          });
        });
      }
    });

    return resultArr as IDependentOption[];
  }
);

export const getPristineInstancesOptions = createSelector(
  [
    getRolesWithProvisionedInstances,
    getGroupRoles,
    (state) => hasFeatureFlags(state, [FEATURE_FLAG_CONSTANTS.BUNDLED_ROLE]),
  ],
  (
    rolesWithInstances,
    assignedRoles,
    useRoleDescriptionForProductInstanceDisplay
  ) => {
    const assignedRolesAndInstancesIds = assignedRoles.map((assignedRole) => {
      return {
        roleId: assignedRole.roleId,
        instanceId: assignedRole.tenantId,
      };
    });

    return rolesWithInstances.reduce<IDependentOption>((acc, role) => {
      const unassignedInstances = role.instances.filter(
        (instance: IDependentOption) => {
          return !assignedRolesAndInstancesIds.find(
            (assignedRoleAndInstanceIds) => {
              return (
                assignedRoleAndInstanceIds.roleId === role.id &&
                (assignedRoleAndInstanceIds.instanceId === instance.id ||
                  instance.id === ENTERPRISE_PRODUCT_TYPE)
              );
            }
          );
        }
      );

      if (!unassignedInstances.length) return acc;

      acc[role.productAndRole] = unassignedInstances.map(
        (instance: IDependentOption) => {
          return {
            value: instance.id,
            label: getProductInstanceDisplayName(
              instance,
              useRoleDescriptionForProductInstanceDisplay
            ),
            roleId: role.id,
            selected: false,
            type: role.type,
          };
        }
      );
      return acc;
    }, {});
  }
);

export const getPristineProductsAndRolesOptions = createSelector(
  [
    getPristineInstancesOptions,
    (state) =>
      hasFeatureFlags(state, [FEATURE_FLAG_CONSTANTS.IAM_SYSTEM_GROUPS_10194]),
  ],
  (pristineInstancesOptions, hasSystemGroupFeatureFlag) => {
    const pristineProductsAndRolesOptions = Object.keys(
      pristineInstancesOptions
    ).map((productAndRoleName) => {
      const roleType = pristineInstancesOptions[productAndRoleName][0]?.type;
      return {
        label: productAndRoleName,
        value: productAndRoleName,
        selected: false,
        type: roleType,
      };
    });
    const filteredpristineProductsAndRoles =
      pristineProductsAndRolesOptions.filter(
        (role) => role.type !== UserRoleType.BUNDLED
      );

    return sortBy(
      hasSystemGroupFeatureFlag
        ? filteredpristineProductsAndRoles
        : pristineProductsAndRolesOptions,
      ['label']
    );
  }
);

export const getIsLastRoleSelected = createSelector(
  [getAssignRolesDrawerTable],
  (assignRolesDrawerTable) => {
    const lastRow = [...assignRolesDrawerTable].pop();
    return Boolean(lastRow?.selectedRole);
  }
);

export const getSelectedRoleIds = createSelector(
  [getAssignRolesDrawerTable],
  (assignRolesDrawerTable) => {
    const selectedIds = [] as string[];

    assignRolesDrawerTable.forEach(({ rolesOptions }: IRolesRows) => {
      rolesOptions.forEach((item: IOption) => {
        if (item.selected) {
          selectedIds.push(item.value);
        }
      });
    });

    return selectedIds;
  }
);

export const getSelectedIntanceIds = createSelector(
  [getAssignRolesDrawerTable],
  (assignRolesWithInstancesTable) => {
    const selectedInstancesIDsInDrawer: Array<{
      roleId: string;
      instanceId: string;
    }> = assignRolesWithInstancesTable.reduce((acc: any, row) => {
      if (!row.selectedRole) return acc;
      const instanceOptions = Object.values(row.instancesOptions);
      instanceOptions.forEach((roleOptions: any) => {
        roleOptions.forEach((option: any) => {
          if (option.selected) {
            acc.push({
              roleId: option.roleId,
              instanceId: option.value,
            });
          }
        });
      });
      return acc;
    }, []);
    return Array.from(
      new Set(
        selectedInstancesIDsInDrawer.map((item: Object) => JSON.stringify(item))
      )
    ).map((item) => JSON.parse(item));
  }
);

export const getRoleTypeOptions = createSelector(
  [getEditGroup],
  ({ assignRolesTable: { typeFilterOptions, typeFilterSelectedOption } }) => {
    if (typeFilterSelectedOption) {
      return typeFilterOptions.map((option) =>
        typeFilterSelectedOption.value === option.value
          ? {
              ...option,
              selected: true,
            }
          : option
      );
    }
    return typeFilterOptions;
  }
);

export const getKPAManagedGroups = createSelector(
  [getKPAManagedTenantIds, getAllGroups, getGroupsToRolesRelations],
  (tenantIds, allGroups, groupsToRolesRelations) => {
    return classifyGroupsForKpa(tenantIds, allGroups, groupsToRolesRelations);
  }
);

export const getFilteredAssignedRolesForKpa = createSelector(
  [getSlicedAssignedRolesTableData, getKPAManagedTenants],
  (slicedTableData, tenants) => {
    const tenantsToFilter = tenants.map((item) => item.id);
    const filteredRolesForKpa = slicedTableData.filter((item) =>
      tenantsToFilter.includes(item.tenantId)
    );

    return filteredRolesForKpa;
  }
);

export const getFilteredGroupsForKpa = createSelector(
  [getKPAManagedGroups, getGroupsTableSearchField],
  (groups, groupsTableSearchField) => {
    const allKPAGroups = [
      ...groups.noRolesGroups,
      ...groups.fullyManagedGroups,
      ...groups.partiallyManagedGroups,
    ];

    const filterDataBySearch = groupsTableSearchField
      ? allKPAGroups.filter((group) =>
          group.name
            .toLowerCase()
            .includes(groupsTableSearchField.toLowerCase())
        )
      : allKPAGroups;

    return filterDataBySearch;
  }
);

export const getIsLoading = createSelector(
  [getState],
  ({ isLoading }) => isLoading
);
