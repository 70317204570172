export enum GroupsNotificationType {
  CREATE_GROUP = 'CREATE_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  ERROR = 'ERROR',
  UPDATE_GROUP = 'UPDATE_GROUP',
  ASSIGN_ROLES = 'ASSIGN_ROLES',
  CHANGE_GROUP_NAME = 'CHANGE_GROUP_NAME',
  REMOVE_USERS = 'REMOVE_USERS',
  REMOVE_ROLES = 'REMOVE_ROLES',
  CREATE_GROUP_MAPPINGS = 'CREATE_GROUP_MAPPINGS',
  UPDATE_GROUP_MAPPINGS = 'UPDATE_GROUP_MAPPINGS',
  CREATE_GROUP_MAPPINGS_FAILURE = 'CREATE_GROUP_MAPPINGS_FAILURE',
}

export enum GroupType {
  Local = 'Local',
  Federated = 'Federated',
  System = 'System',
  Shared = 'Shared',
}

export enum RemovedUserMode {
  Successful = 'successful',
  Failed = 'failed',
}
