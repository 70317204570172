import { get } from 'lodash';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  fetchEnterprises as fetchEnterprisesAction,
  fetchEnterprisesSuccess,
  fetchEnterprisesError,
  fetchEnterpriseDetailsSuccess,
  fetchEnterpriseDetailsError,
  createEnterpriseSuccess,
  createEnterpriseError,
  showSuccessCreateEnterpriseBanner,
  createEnterprise as createEnterpriseAction,
  setDefaultEnterpriseError,
  setDefaultEnterpriseSuccess,
  closeEnterpriseModal,
  setDefaultEnterprisePending,
  setDefaultEnterpriseUserId,
} from './actions';
import {
  FETCH_ENTERPRISES_PENDING,
  FETCH_ENTERPRISE_DETAILS_PENDING,
  CREATE_ENTERPRISE_PENDING,
  SET_DEFAULT_ENTERPRISE_PENDING,
  SET_ENTERPRISE_DETAILS,
  SHOW_SUCCESS_CREATE_ENTERPRISE_BANNER,
  HIDE_SUCCESS_CREATE_ENTERPRISE_BANNER,
  HIDE_SUCCESS_SWITCH_ENTERPRISE_TOAST,
} from './constants';
import { filterEnterprises, sortEnterprises } from './helpers';
import {
  ICreateEnterpriseAction,
  IEnterpriseDetailsPayloadType,
  IFetchEnterprisesAction,
  ISetDefaultEnterpriseAction,
  ISetDefaultEnterpriseID,
} from './interfaces';
import { getAllEnterprises, getEnterpriseId } from './selectors';
import { prometheusAPIClient } from '../../api/clients';
import {
  IDefaultEnterprise,
  IEnterpriseObject,
} from '../../interfaces/IEnterpriseList';
import { OVERVIEW } from '../../paths';
import { CloseErrorBanners } from '../common/actions';
import { sendBootstrapPlatformRequest } from '../overview/store/actions';

export type EnterprisesDetailsResponseType = { data: IEnterpriseObject } | any;

export type EnterprisesResponseType = { data: IEnterpriseObject[] } | any;

export type DefaultEnterpriseResponseType = { data: IDefaultEnterprise } | any;

export function* fetchEnterpriseDetails(
  action: IEnterpriseDetailsPayloadType
): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const { navigate } = action.payload;
  try {
    const response: EnterprisesDetailsResponseType =
      yield prometheusAPIClient.get(`/api/enterprises/${enterpriseId}`);

    if (get(response, 'data.id') && get(response, 'data.name')) {
      yield put(fetchEnterpriseDetailsSuccess(response.data));
      yield call(navigate, OVERVIEW);
    } else {
      yield put(
        fetchEnterpriseDetailsError({ message: 'Response data is missing' })
      );
      yield put(fetchEnterprisesAction());
    }
  } catch (error) {
    yield put(fetchEnterpriseDetailsError({ message: error } as Error));
    yield put(fetchEnterprisesAction());
  }
}

export function* fetchEnterprises(action: IFetchEnterprisesAction): Generator {
  try {
    const response: EnterprisesResponseType =
      yield prometheusAPIClient.get(`/api/enterprises`);
    yield put(
      fetchEnterprisesSuccess(
        sortEnterprises(filterEnterprises(response.data.items))
      )
    );
    if (action.payload) {
      yield put(
        setDefaultEnterprisePending({
          oktaAuth: action.payload.oktaAuth,
          navigate: action.payload.navigate,
          shouldRedirect: action.payload.shouldRedirect,
          basepath: action.payload.basepath,
        })
      );
    }
  } catch (error) {
    yield put(fetchEnterprisesError(error as Error));
  }
}

export function* createEnterprise(action: ICreateEnterpriseAction): Generator {
  try {
    const response: EnterprisesResponseType = yield prometheusAPIClient.post(
      `/api/enterprises/createFromUser`,
      {
        name: action.payload.enterpriseName,
      }
    );
    const renewToken = async (): Promise<void> => {
      await action.payload?.oktaAuth?.tokenManager?.renew('accessToken');
    };
    yield prometheusAPIClient.put(`/api/defaultEnterprise/${response.data.id}`);
    yield put(closeEnterpriseModal());
    yield put(showSuccessCreateEnterpriseBanner());
    yield call(renewToken);
    yield put(createEnterpriseSuccess(response.data));
    yield put(setDefaultEnterpriseSuccess(response.data));
    yield put(fetchEnterprisesAction());
    try {
      yield put(
        sendBootstrapPlatformRequest(response.data.id, response.data.name)
      );
    } catch {
      // do nothing and dont throw/show this error since this feature is hidden from customers
    }
    yield call(action.payload?.navigate, '/overview');
  } catch (error) {
    yield put(createEnterpriseError(error as Error));
  }
}

export function* setDefaultEnterprise(
  action: ISetDefaultEnterpriseAction
): Generator {
  const enterprises = yield select(getAllEnterprises);
  const {
    payload: { oktaAuth, navigate, shouldRedirect },
  } = action;
  try {
    const response: DefaultEnterpriseResponseType =
      yield prometheusAPIClient.get(`/api/defaultEnterprise`);
    yield put(setDefaultEnterpriseUserId(response.data.userId));

    const defaultEnterpriseId =
      response.status === 204 ? null : response.data?.enterpriseId;

    const currentDefaultEnterprise = (enterprises as IEnterpriseObject[]).find(
      (enterprise) => enterprise.id === defaultEnterpriseId
    );
    const isDefaultEnterpriseExistForUser = Boolean(currentDefaultEnterprise);

    if (
      !isDefaultEnterpriseExistForUser &&
      (enterprises as IEnterpriseObject[]).length === 0
    ) {
      yield put(
        createEnterpriseAction('Default Organization', oktaAuth, navigate)
      );
    }
    if (isDefaultEnterpriseExistForUser) {
      const enterprise = {
        id: (currentDefaultEnterprise as IEnterpriseObject).id,
        name: (currentDefaultEnterprise as IEnterpriseObject).name,
      };
      yield prometheusAPIClient.put(`/api/defaultEnterprise/${enterprise.id}`);
      yield put(setDefaultEnterpriseSuccess(enterprise));
    } else {
      const enterprise = {
        id: (enterprises as IEnterpriseObject[])[0].id,
        name: (enterprises as IEnterpriseObject[])[0].name,
      };
      yield prometheusAPIClient.put(`/api/defaultEnterprise/${enterprise.id}`);
      yield put(setDefaultEnterpriseSuccess(enterprise));
    }

    yield put(CloseErrorBanners());

    if (shouldRedirect) {
      const basepath =
        action.payload.basepath !== '/'
          ? `${action.payload.basepath}/`
          : action.payload.basepath;
      yield call(action.payload?.navigate, `${basepath}${OVERVIEW}`);
    }
  } catch (error) {
    setDefaultEnterpriseError(error as Error);
  }
}

export function* setEnterpriseDetails(
  action: ISetDefaultEnterpriseID
): Generator {
  try {
    const defaultEnterprise: DefaultEnterpriseResponseType =
      yield prometheusAPIClient.put(
        `/api/defaultEnterprise/${action.payload.id}`
      );
    yield put(setDefaultEnterpriseUserId(defaultEnterprise.data.userId));
  } catch (error) {
    setDefaultEnterpriseError(error as Error);
  }
}

export function* showCreateEnterpriseBanner(): Generator {
  const showCreateBanner = (): void => {
    sessionStorage.setItem('showCreateEnterpriseBanner', 'true');
  };
  yield call(showCreateBanner);
}

export function* hideCreateEnterpriseBanner(): Generator {
  const hideCreateBanner = (): void => {
    sessionStorage.removeItem('showCreateEnterpriseBanner');
  };
  yield call(hideCreateBanner);
}

export function* hideSwitchEnterpriseBanner(): Generator {
  const hideCreateBanner = (): void => {
    sessionStorage.removeItem('showSwitchEnterprisesToast');
  };
  yield call(hideCreateBanner);
}

export function* enterprisesSaga(): Generator {
  yield takeLatest(FETCH_ENTERPRISES_PENDING, fetchEnterprises);
  yield takeLatest(FETCH_ENTERPRISE_DETAILS_PENDING, fetchEnterpriseDetails);
  yield takeLatest(CREATE_ENTERPRISE_PENDING, createEnterprise);
  yield takeLatest(SET_DEFAULT_ENTERPRISE_PENDING, setDefaultEnterprise);
  yield takeLatest(SET_ENTERPRISE_DETAILS, setEnterpriseDetails);
  yield takeLatest(
    SHOW_SUCCESS_CREATE_ENTERPRISE_BANNER,
    showCreateEnterpriseBanner
  );
  yield takeLatest(
    HIDE_SUCCESS_CREATE_ENTERPRISE_BANNER,
    hideCreateEnterpriseBanner
  );
  yield takeLatest(
    HIDE_SUCCESS_SWITCH_ENTERPRISE_TOAST,
    hideSwitchEnterpriseBanner
  );
}
