import {
  UploadChangeParam,
  UploadFile,
} from '@ciscodesignsystems/cds-react-upload/src/lib';
import csv from 'csvtojson';
import {
  every,
  filter,
  get,
  isArray,
  isEmpty,
  mapValues,
  values,
  chain,
} from 'lodash';
import { END, EventChannel, eventChannel } from 'redux-saga';
import { IRbacUserRole } from 'src/app/interfaces/IUserRoles';
import { IManagerAndManagedOrgOfSharedUserOrGroup } from 'src/app/interfaces/IUsers';
import { SetUploadCSVProgress, SetUserError } from './actions';
import {
  CSV_COLUMNS_KEYS,
  CSV_COLUMNS_TEXT,
  FILE_ERROR,
  FILE_LOADED,
  UPLOAD_CSV_FILE_MAX_SIZE,
} from './constants';
import {
  ICreateAllUsers,
  IDependentOption,
  IOption,
  IRbacAssignedRole,
  IRbacGroup,
  IRolesRows,
  IRolesWithInstancesRows,
  ISecurityCloudControlOption,
  IUserFormFieldError,
  IUserFormRow,
} from './interfaces';
import {
  ErrorMessagesType,
  ErrorUploadMessageType,
} from '../../enums/AddUsersStepper';
import { RolesPermission } from '../../enums/RolesPermissionList';
import { IUser } from '../../interfaces/ICommon';
import { FileReaderEventActionType } from '../../interfaces/IFileReaderEventActions';
import { IUserCSVColumns } from '../../interfaces/IUserCSVColumns';
import { validateEmail } from '../../utils/validation';

export const selectNewlyCreatedGroupOption = (
  groupOptions: IOption[],
  currentGroupsOptions: IOption[],
  selectRow: string
): IOption[] => {
  const updatedGroupOptions = groupOptions.map((groupOption) => {
    const currentOption = currentGroupsOptions.find(
      (currentGroupOption) => currentGroupOption.value === groupOption.value
    );
    let selected = currentOption
      ? currentOption.selected
      : groupOption.selected;

    if (groupOption.label === selectRow) {
      selected = true;
    }

    return { ...groupOption, selected };
  });

  return updatedGroupOptions;
};

export const updateRolesOptionsSelected = (
  rolesOptions: IDependentOption,
  selectedOptionsMap: Map<string, IOption>
): IDependentOption =>
  Object.fromEntries(
    Object.entries(rolesOptions).map(
      ([productName, availableRoles]: IDependentOption) => {
        const updatedRoles = availableRoles.map((parentOption: IOption) => {
          return selectedOptionsMap.get(parentOption.value) ?? parentOption;
        });
        return [productName, updatedRoles];
      }
    )
  );

export const rowChangeProductOptionsToSelected = (
  assignRolesTable: IRolesRows[],
  rolesOptions: IDependentOption,
  selectedOption: IOption,
  currentRow: number
): IRolesRows[] =>
  assignRolesTable.map((row, indexRow) => {
    if (indexRow === currentRow) {
      const updatedProductsOptions = row.productsOptions.map((option) => ({
        ...option,
        selected: option.value === selectedOption.value,
      }));
      return {
        ...row,
        selectedProduct: selectedOption.value,
        productsOptions: updatedProductsOptions,
        rolesOptions,
      };
    }
    return row;
  });

export const rowChangeProductAndRoleOptionsToSelected = (
  assignRolesTable: IRolesWithInstancesRows[],
  instancesOptions: IDependentOption,
  selectedOption: IOption,
  currentRow: number
): IRolesWithInstancesRows[] =>
  assignRolesTable.map((row, indexRow) => {
    if (indexRow === currentRow) {
      const updatedProductsAndRolesOptions = row.rolesOptions.map((option) => ({
        ...option,
        selected: option.value === selectedOption.value,
      }));

      return {
        ...row,
        selectedRole: selectedOption.label,
        rolesOptions: updatedProductsAndRolesOptions,
        instancesOptions,
      };
    }
    return row;
  });

export const rowClearProductOptionsToUnselected = (
  assignRolesTable: IRolesRows[],
  currentRow: number
): IRolesRows[] =>
  assignRolesTable.map((row, indexRow) => {
    if (indexRow === currentRow) {
      const updatedProductsOptions = row.productsOptions.map((option) => ({
        ...option,
        selected: false,
      }));

      return {
        ...row,
        selectedProduct: null,
        productsOptions: updatedProductsOptions,
      };
    }
    return row;
  });

export const rowChangeRoleOptionsToSelected = (
  assignRolesTable: IRolesRows[],
  rolesOptions: IDependentOption,
  selectedOptionsMap: Map<string, IOption>,
  currentRow: number
): IRolesRows[] =>
  assignRolesTable.map((row, indexRow) => {
    if (indexRow === currentRow) {
      return {
        ...row,
        rolesOptions: updateRolesOptionsSelected(
          rolesOptions,
          selectedOptionsMap
        ),
      };
    }
    return row;
  });

export const formatSharedOrgNames = (
  managedOrg: IManagerAndManagedOrgOfSharedUserOrGroup[]
): string => {
  if (managedOrg.length === 0) {
    return '';
  }
  if (managedOrg.length === 1) {
    return managedOrg[0].name;
  }
  const orgNames: string[] = managedOrg.map((item) => item.name);
  return (
    orgNames.slice(0, -1).join(', ') + ' & ' + orgNames[orgNames.length - 1]
  );
};
export const updateInstancesOptionsSelected = (
  instancesOptions: IDependentOption,
  selectedOptionsMap: Map<string, IOption>
): IDependentOption =>
  Object.fromEntries(
    Object.entries(instancesOptions).map(
      ([productAndRoleName, availableInstances]: IDependentOption) => {
        const updatedInstances = availableInstances.map(
          (parentOption: IOption) => {
            return selectedOptionsMap.get(parentOption.value) ?? parentOption;
          }
        );
        return [productAndRoleName, updatedInstances];
      }
    )
  );

export const rowChangeInstancesOptionsToSelected = (
  assignRolesTable: IRolesWithInstancesRows[],
  instancesOptions: IDependentOption,
  selectedOptionsMap: Map<string, IOption>,
  currentRow: number
): IRolesWithInstancesRows[] =>
  assignRolesTable.map((row, indexRow) => {
    if (indexRow === currentRow) {
      return {
        ...row,
        instancesOptions: updateInstancesOptionsSelected(
          instancesOptions,
          selectedOptionsMap
        ),
      };
    }
    return row;
  });

export const rowClearRoleOptionsToUnselected = (
  assignRolesTable: IRolesRows[],
  rolesOptions: IDependentOption,
  currentRow: number
): IRolesRows[] =>
  assignRolesTable.map((row, indexRow) => {
    if (indexRow === currentRow) {
      return {
        ...row,
        rolesOptions,
      };
    }
    return row;
  });

export const rowChangeSecurityCloudControl = (
  setSCCOptions: React.Dispatch<
    React.SetStateAction<ISecurityCloudControlOption[]>
  >,
  selectedOption: RolesPermission
): void => {
  setSCCOptions((prevState) =>
    prevState.map((sccOption) => ({
      ...sccOption,
      selected: sccOption.value === selectedOption,
    }))
  );
};

export const transformFileToInviteUsers = (
  fileLinesArray: IUserCSVColumns[]
): IUserFormRow[] => {
  return fileLinesArray.map((fileLine) => ({
    firstName: {
      value: fileLine.firstName ?? fileLine['First Name'] ?? '',
      errorStatus: false,
      errorMessage: '',
    },
    lastName: {
      value: fileLine.lastName ?? fileLine['Last Name'] ?? '',
      errorStatus: false,
      errorMessage: '',
    },
    email: {
      value: fileLine.email ?? fileLine.Email ?? '',
      errorStatus: false,
      errorMessage: '',
    },
  }));
};

export const transformInviteUsers = (
  inviteUsers: IUserFormRow[],
  sccRole: boolean
): ICreateAllUsers[] => {
  return inviteUsers.map((user) => {
    const baseUser = {
      firstName: user.firstName.value,
      lastName: user.lastName.value,
      email: user.email.value,
      login: user.email.value,
    };

    return sccRole
      ? {
          ...baseUser,
          enterpriseRole: RolesPermission.ADMINISTRATOR.toLowerCase(),
        }
      : baseUser;
  });
};

export const transformGroupsToGroupsOptions = (
  rbacGroups: IRbacGroup[]
): IOption[] => {
  return rbacGroups.map(({ id, name }) => ({
    value: id,
    label: name,
    selected: false,
  }));
};

export const toProductOptions = (rbacRoles: IRbacUserRole[]): IOption[] => {
  const uniqueValues = Array.from(
    new Set(rbacRoles.map(({ product }: IRbacUserRole) => product))
  );
  return uniqueValues.map((productName) => ({
    value: productName,
    label: productName,
    selected: false,
  }));
};

const transformRoleName = (roleName: string): string => {
  if (!roleName) return '';
  return roleName.replace(/(?!^)([A-Z])(?=[a-z])/g, ' $1').trim();
};

export const toRoleOptions = (rbacRoles: IRbacUserRole[]): IDependentOption => {
  return rbacRoles.reduce<IDependentOption>(
    (result, { id, roleDisplayName, product }) => {
      const readableRole = roleDisplayName
        ? transformRoleName(roleDisplayName)
        : '';
      if (!result[product]) result[product] = [];
      if (readableRole) {
        result[product].push({
          value: id,
          label: readableRole,
          selected: false,
        });
      }
      return result;
    },
    {}
  );
};

export const validateEmailAlreadyExist = (
  usersList: IUser[],
  fieldValue: string
): boolean => {
  return usersList.some((user) => {
    const email = user.profile.email;
    return (
      email !== undefined && email.toLowerCase() === fieldValue.toLowerCase()
    );
  });
};

export const validateUploadChange = (
  info: UploadChangeParam<UploadFile>
): string | null => {
  if (info.fileList && isArray(info.fileList) && info.fileList.length > 1) {
    return ErrorUploadMessageType.ONE_FILE_LIMIT;
  }
  if (
    typeof info.file.name === 'string' &&
    info.file.name.split('.').pop() !== 'csv'
  ) {
    return ErrorUploadMessageType.NOT_A_CSV_FILE;
  }
  if (
    typeof info.file.size === 'number' &&
    info.file.size > UPLOAD_CSV_FILE_MAX_SIZE
  ) {
    return ErrorUploadMessageType.TOO_LARGE_FILE;
  }
  return null;
};

export const hasRequiredFields = (
  obj: Partial<IUserCSVColumns>,
  columns: Array<keyof IUserCSVColumns>
): boolean => {
  return every(columns, (c) => !isEmpty(get(obj, c)));
};

export const validateFileLines = (
  fileArr: IUserCSVColumns[]
): string | null => {
  if (isArray(fileArr) && fileArr.length > 20) {
    return ErrorUploadMessageType.MAX_USERS;
  }
  if (
    isArray(fileArr) &&
    !(
      hasRequiredFields(fileArr[0], CSV_COLUMNS_KEYS) ||
      hasRequiredFields(fileArr[0], CSV_COLUMNS_TEXT)
    )
  ) {
    return ErrorUploadMessageType.CSV_HAS_NO_KEYS;
  }
  return null;
};

export const createFileReaderChannel = (
  file: File
): EventChannel<FileReaderEventActionType> => {
  return eventChannel((emitter) => {
    const reader = new FileReader();

    reader.onloadstart = () => {
      emitter(SetUploadCSVProgress(0));
      emitter(SetUserError(null));
    };

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        emitter(SetUploadCSVProgress(progress));
      }
    };

    reader.onload = async () => {
      const fileContent = reader.result;
      const fileLinesArray = await csv().fromString(fileContent as string);

      const fileLinesError = validateFileLines(fileLinesArray);
      if (fileLinesError) {
        emitter({
          type: FILE_ERROR,
          payload: fileLinesError,
        });
        emitter(END);
      }

      emitter({
        type: FILE_LOADED,
        payload: transformFileToInviteUsers(fileLinesArray),
      });
      emitter(END);
    };

    reader.onerror = () => {
      emitter({
        type: FILE_ERROR,
        payload: ErrorUploadMessageType.FAILED_UPLOAD,
      });
      emitter(END);
    };

    reader.readAsText(file);

    return () => {
      reader.abort();
    };
  });
};

export const getErrorStatusWithMessage = (
  fieldName: string,
  fieldValue: string,
  allUsers: IUser[],
  isUserKPA: boolean
): IUserFormFieldError => {
  const emptyErrorStatus = { errorMessage: '', errorStatus: false };

  const existUserInEnterprise = allUsers.some(
    (item) => item.profile.email === fieldValue
  );

  if (!fieldValue) {
    return {
      ...emptyErrorStatus,
      errorStatus: true,
    };
  }

  if (
    (fieldName === 'firstName' || fieldName === 'lastName') &&
    fieldValue.length > 50
  ) {
    return {
      errorMessage: ErrorMessagesType.INVITE_FLOW_USER_NAME_MAX_CHARACTERS,
      errorStatus: true,
    };
  }

  if (fieldName === 'email') {
    if (isUserKPA && existUserInEnterprise) return emptyErrorStatus;
    if (!validateEmail(fieldValue)) {
      return {
        errorMessage: ErrorMessagesType.EMAIL_FORMAT,
        errorStatus: true,
      };
    }

    if (validateEmailAlreadyExist(allUsers, fieldValue)) {
      return {
        errorMessage: ErrorMessagesType.EMAIL_EXISTS,
        errorStatus: true,
      };
    }
  }

  return emptyErrorStatus;
};

export const getNoIconCellClassName = (
  baseClass: string,
  columnsOptionsLength: number,
  columnIndex: number
): string => {
  const noRowIconClass =
    columnIndex === columnsOptionsLength - 1 ? ' no-row-icon' : '';
  return `${baseClass}${noRowIconClass}`;
};

export const getRoleName = (
  selectedProductsInAssignRolesDrawer: IRolesRows[]
): string | null => {
  const selectedProduct =
    selectedProductsInAssignRolesDrawer[0].selectedProduct;
  if (selectedProduct) {
    const selectedOption = selectedProductsInAssignRolesDrawer[0].rolesOptions[
      selectedProduct
    ].find((option: IOption) => option.selected);
    return selectedOption?.label ?? null;
  }
  return null;
};

export const isAnyRowFilled = (rows: IUserFormRow[]): boolean => {
  const transformedRows = rows.map((row) => mapValues(row, 'value'));
  return transformedRows.some((row) =>
    values(row).some((value) => value.length)
  );
};

export const removeUserRolesFromRolesOptions = (
  productRolesOptions: IDependentOption,
  rbacAssignedRoles: IRbacAssignedRole[]
): IDependentOption => {
  const filteredRoles = (rolesOptions: IOption[]): IOption[] =>
    filter(rolesOptions, (rolesOption) => {
      return !rbacAssignedRoles
        .map((rbacAssignedRole) => rbacAssignedRole.roleId)
        .includes(rolesOption.value);
    });

  return mapValues(productRolesOptions, (value) => filteredRoles(value));
};

export const removeProductsOptionsWithoutUnassignedRoles = (
  productsOptions: IOption[],
  unassignedRolesOptions: IDependentOption
): IOption[] => {
  return productsOptions.filter((productsOption) =>
    Boolean(unassignedRolesOptions[productsOption.value].length)
  );
};

export const getIsTableDirty = (dataArray: IUserFormRow[]): boolean => {
  for (const row of dataArray) {
    for (const fieldKey in row) {
      const field = row[fieldKey];
      if (field.value !== '') {
        return true;
      }
    }
  }

  return false;
};

export const getFilteredAndSortedOptions = (
  productsAndRolesOptions: IOption[],
  selectedProductsAndRoles: Array<string | null>
): IOption[] => {
  return chain(productsAndRolesOptions)
    .filter((option) => !selectedProductsAndRoles.includes(option.label))
    .sortBy(['label'])
    .value();
};
