import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';

import { IIdp } from 'src/app/interfaces/IIdentityProvider';
import {
  FetchIdentityProviders,
  FetchExternalIdentityProviders,
  ShowAddExternalIdpModal,
  HideAddExternalIdpModal,
  ChangeAddExternalIdpModalStep,
  ClearAddExternalIdpData,
  ChangeIpdIdInputValue,
  ValidateIpdIdInputValue,
  VerifyExternalIdp,
  CreateExternalIdp,
  AddIdpNotification,
  HideIdpNotification,
  UpdateIdpGroupMappings,
  DeleteExternalIdp,
  ShowDeleteExternalIdpModal,
  HideDeleteExternalIdpModal,
  ClearDeleteExternalIdpData,
  SetDeleteExternalIdpData,
} from './actions';
import { IDP_ID_MAX_LENGTH } from './constants';

export const initialState: IIdp = {
  idp: {
    data: [],
    error: null,
    loading: false,
  },
  addExternalIdp: {
    isLoading: false,
    error: null,
    addExternalIdpModal: {
      step: 'add', // step: add; confirm.
      isModalVisible: false,
      idpIdInputValue: '',
      isIdpIdInputValueValid: true,
    },
    externalIdpToBeAdded: {
      data: null,
      isLoading: false,
      error: null,
    },
  },
  editGroupMappings: {
    isLoading: false,
    error: null,
  },
  deleteExternalIdp: {
    isLoading: false,
    error: null,
    externalIdpToBeDeleted: {
      data: null,
    },
    deleteExternalIdpModal: {
      isModalVisible: false,
    },
  },
  notification: {
    type: null,
    traceId: null,
  },
  externalIdp: {
    data: [],
    error: null,
    loading: false,
  },
};

export default createReducer(initialState, {
  [FetchIdentityProviders.Pending.TYPE]: (state: IIdp) => ({
    ...state,
    idp: {
      data: [],
      loading: true,
      error: null,
    },
  }),

  [FetchIdentityProviders.Success.TYPE]: (
    state: IIdp,
    { payload }: AnyAction
  ) => ({
    ...state,
    idp: {
      data: payload,
      loading: false,
      error: null,
    },
  }),

  [FetchIdentityProviders.Error.TYPE]: (
    state: IIdp,
    { payload }: AnyAction
  ) => ({
    ...state,
    idp: {
      data: [],
      error: payload,
      loading: false,
    },
  }),

  [VerifyExternalIdp.Pending.TYPE]: (state: IIdp) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      externalIdpToBeAdded: {
        data: null,
        isLoading: true,
        error: null,
      },
    },
  }),

  [VerifyExternalIdp.Success.TYPE]: (state: IIdp, { payload }: AnyAction) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      externalIdpToBeAdded: {
        data: payload,
        isLoading: false,
        error: null,
      },
    },
  }),

  [VerifyExternalIdp.Error.TYPE]: (state: IIdp, { payload }: AnyAction) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      externalIdpToBeAdded: {
        data: null,
        isLoading: false,
        error: payload,
      },
    },
  }),

  [CreateExternalIdp.Pending.TYPE]: (state: IIdp) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      isLoading: true,
      error: null,
    },
  }),

  [CreateExternalIdp.Success.TYPE]: (state: IIdp) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      isLoading: false,
      error: null,
    },
  }),

  [CreateExternalIdp.Error.TYPE]: (state: IIdp, { payload }: AnyAction) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      isLoading: false,
      error: payload,
    },
  }),

  [UpdateIdpGroupMappings.Pending.TYPE]: (state: IIdp) => ({
    ...state,
    editGroupMappings: {
      ...state.editGroupMappings,
      isLoading: true,
      error: null,
    },
  }),

  [UpdateIdpGroupMappings.Success.TYPE]: (state: IIdp) => ({
    ...state,
    editGroupMappings: {
      ...state.editGroupMappings,
      isLoading: false,
      error: null,
    },
  }),

  [UpdateIdpGroupMappings.Error.TYPE]: (
    state: IIdp,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroupMappings: {
      ...state.editGroupMappings,
      isLoading: false,
      error: payload,
    },
  }),

  [ShowAddExternalIdpModal.TYPE]: (state: IIdp) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      addExternalIdpModal: {
        ...state.addExternalIdp.addExternalIdpModal,
        isModalVisible: true,
      },
    },
  }),

  [HideAddExternalIdpModal.TYPE]: (state: IIdp) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      addExternalIdpModal: {
        ...state.addExternalIdp.addExternalIdpModal,
        isModalVisible: false,
      },
    },
  }),

  [ChangeAddExternalIdpModalStep.TYPE]: (
    state: IIdp,
    { payload }: AnyAction
  ) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      addExternalIdpModal: {
        ...state.addExternalIdp.addExternalIdpModal,
        step: payload as 'add' | 'confirm',
      },
    },
  }),

  [ChangeIpdIdInputValue.TYPE]: (state: IIdp, { payload }: AnyAction) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      addExternalIdpModal: {
        ...state.addExternalIdp.addExternalIdpModal,
        idpIdInputValue: payload,
      },
    },
  }),

  [ValidateIpdIdInputValue.TYPE]: (state: IIdp, { payload }: AnyAction) => ({
    ...state,
    addExternalIdp: {
      ...state.addExternalIdp,
      addExternalIdpModal: {
        ...state.addExternalIdp.addExternalIdpModal,
        isIdpIdInputValueValid: payload.length <= IDP_ID_MAX_LENGTH,
      },
    },
  }),

  [ClearAddExternalIdpData.TYPE]: (state: IIdp) => {
    return {
      ...state,
      addExternalIdp: {
        isLoading: false,
        error: null,
        addExternalIdpModal: {
          ...state.addExternalIdp.addExternalIdpModal,
          step: 'add',
          idpIdInputValue: '',
          isIdpIdInputValueValid: true,
        },
        externalIdpToBeAdded: {
          data: null,
          isLoading: false,
          error: null,
        },
      },
    };
  },

  [AddIdpNotification.TYPE]: (state: IIdp, { payload }: AnyAction) => ({
    ...state,
    notification: {
      type: payload.type,
      traceId: payload?.traceId ?? null,
    },
  }),

  [HideIdpNotification.TYPE]: (state: IIdp) => ({
    ...state,
    notification: {
      ...initialState.notification,
    },
  }),

  [FetchExternalIdentityProviders.Pending.TYPE]: (state: IIdp) => ({
    ...state,
    externalIdp: {
      data: [],
      loading: true,
      error: null,
    },
  }),

  [FetchExternalIdentityProviders.Success.TYPE]: (
    state: IIdp,
    { payload }: AnyAction
  ) => ({
    ...state,
    externalIdp: {
      data: payload,
      loading: false,
      error: null,
    },
  }),

  [FetchExternalIdentityProviders.Error.TYPE]: (
    state: IIdp,
    { payload }: AnyAction
  ) => ({
    ...state,
    externalIdp: {
      data: [],
      error: payload,
      loading: false,
    },
  }),

  [ShowDeleteExternalIdpModal.TYPE]: (state: IIdp) => ({
    ...state,
    deleteExternalIdp: {
      ...state.deleteExternalIdp,
      deleteExternalIdpModal: {
        isModalVisible: true,
      },
    },
  }),

  [HideDeleteExternalIdpModal.TYPE]: (state: IIdp) => ({
    ...state,
    deleteExternalIdp: {
      ...state.deleteExternalIdp,
      deleteExternalIdpModal: {
        isModalVisible: false,
      },
    },
  }),

  [ClearDeleteExternalIdpData.TYPE]: (state: IIdp) => {
    return {
      ...state,
      deleteExternalIdp: {
        ...state.deleteExternalIdp,
        isLoading: false,
        error: null,
        externalIdpToBeDeleted: {
          data: null,
        },
      },
    };
  },

  [SetDeleteExternalIdpData.TYPE]: (state: IIdp, { payload }: AnyAction) => {
    return {
      ...state,
      deleteExternalIdp: {
        ...state.deleteExternalIdp,
        externalIdpToBeDeleted: {
          data: payload,
        },
      },
    };
  },

  [DeleteExternalIdp.Pending.TYPE]: (state: IIdp) => ({
    ...state,
    deleteExternalIdp: {
      ...state.deleteExternalIdp,
      isLoading: true,
      error: null,
    },
  }),

  [DeleteExternalIdp.Success.TYPE]: (state: IIdp) => ({
    ...state,
    deleteExternalIdp: {
      ...state.deleteExternalIdp,
      isLoading: false,
      error: null,
    },
  }),

  [DeleteExternalIdp.Error.TYPE]: (state: IIdp, { payload }: AnyAction) => ({
    ...state,
    deleteExternalIdp: {
      ...state.deleteExternalIdp,
      isLoading: false,
      error: payload,
    },
  }),
});
