import { format, subDays, parseISO, isBefore, isValid } from 'date-fns';
import i18next from 'i18next';
import {
  IActivityLog,
  DateRangePickerValue,
  EventType,
} from 'src/app/interfaces/IActivityLog';
import { eventTypesSubTypesOptions } from './constants';
import { IUser } from '../../interfaces/ICommon';
import { IOption } from '../groups/interfaces';

export const getLogUsersEmails = (
  activityLogData: IUser[],
  selectedUsers: string[]
): IOption[] => {
  if (!activityLogData) return [];
  return activityLogData.map((log) => {
    const email = log.profile.email;
    const isSelected = selectedUsers.includes(email);
    return {
      label: email,
      value: email,
      selected: isSelected,
    };
  });
};

export const getSubTypes = (
  selectedSubtypes: string[]
): Array<{ label: string; options: IOption[] }> => {
  const selectedSet = new Set(selectedSubtypes);

  return eventTypesSubTypesOptions.map((eventType) => {
    const options = eventType.options.map((option) => {
      return {
        label: option.label,
        value: option.value,
        selected: selectedSet.has(option.value),
      };
    });

    return {
      label: eventType.label,
      options,
    };
  });
};

export const formatDateToMMDDYYYY = (dateInput: string | Date): string => {
  if (!dateInput) {
    return '';
  }

  const date = typeof dateInput === 'string' ? parseISO(dateInput) : dateInput;
  if (!isValid(date)) {
    return '';
  }

  return format(date, 'MMM d, yyyy');
};

export function getInitialDefaultRange(createdAt: string): [Date, Date] {
  const createdAtDate = parseISO(createdAt);
  const endDate = new Date();
  const ninetyDaysAgo = subDays(endDate, 90);
  const startDate = isBefore(createdAtDate, ninetyDaysAgo)
    ? ninetyDaysAgo
    : createdAtDate;
  return [startDate, endDate];
}

export function parseDateRangePickerValue(
  initialFetchedRange: DateRangePickerValue
): DateRangePickerValue {
  if (initialFetchedRange && initialFetchedRange.length === 2) {
    return [
      typeof initialFetchedRange[0] === 'string'
        ? parseISO(initialFetchedRange[0])
        : initialFetchedRange[0],
      typeof initialFetchedRange[1] === 'string'
        ? parseISO(initialFetchedRange[1])
        : initialFetchedRange[1],
    ];
  }
  return initialFetchedRange === null ? null : undefined;
}

export function formatTimestamp(timestamp: string): string {
  const date = parseISO(timestamp);
  return format(date, 'MM/dd/yyyy HH:mm:ss');
}

export function formatLog(
  log: IActivityLog
): IActivityLog & { eventType: string; eventDate: string } {
  const formattedTimestamp = formatTimestamp(log.eventDate);
  return {
    ...log,
    eventDate: formattedTimestamp as EventType,
  };
}

export function formatLogs(
  logs: IActivityLog[]
): Array<IActivityLog & { eventType: string }> {
  if (Array.isArray(logs)) {
    return logs.map(formatLog);
  } else {
    return [];
  }
}

export const convertSortToBackendFormat = (
  sortArray: Array<{ id: string; desc: boolean }>
): string | null => {
  if (sortArray.length === 0) {
    return '';
  }

  const { id, desc } = sortArray[0];
  const sortOrder = desc ? 'desc' : 'asc';

  return `${id}:${sortOrder}`;
};

export const getTranslationKey = (baseKey: string, count: number): string => {
  const suffix = count === 0 ? '0' : count === 1 ? '1' : 'other';
  return i18next.t(`${baseKey}_${suffix}`);
};

export const isDateDisabled = (
  dateToCheck: Date,
  createdAt: string
): boolean => {
  const enterpriseCreatedDate = new Date(createdAt);
  const today = new Date();
  return dateToCheck < enterpriseCreatedDate || dateToCheck > today;
};
