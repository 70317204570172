import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { AxiosError, AxiosResponse } from 'axios';
import _toUpper from 'lodash/toUpper';
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  FEATURE_FLAG_CONSTANTS,
  FEATURE_FLAG_PREFIX,
} from 'src/app/utils/constant';
import {
  AssignSelectedInstance,
  AssignSelectedInstances,
  CreateGroup,
  DeleteAssignedGroupRole,
  DeleteAssignedGroupUsers,
  DeleteAssignedSingleGroupUser,
  FetchAllGroups,
  FetchAllRoles,
  FetchGroupMappings,
  FetchGroupRoles,
  FetchIdpGroups,
  RefreshGroupsPage,
  SetAssignRolesTableDrawer,
  SetDrawerRolesTable,
  SetRegionFilterOptions,
  ToggleAssignRolesDrawerVisibility,
  ToggleGroupsDrawerVisibility,
  UpdateGroupMappings,
  SetAddGroupStep,
  AddGroup,
  AssignUser,
  AssignUsers,
  FetchGroupUsers,
  DeleteGroup,
  EditGroupName,
  AssignRolesToGroup,
  AssignRoleToGroup,
  FetchGroupById,
  AddUsersToGroup,
  ShowDeleteGroupModal,
  HideAddUsersDrawer,
  ShowEditGroupNameDrawer,
  HideAssignRolesDrawer,
  SetUsersOptionsFromDrawer,
  SetUsersOptions,
  ChangeNameField,
  AddNotification,
  ChangeDescriptionField,
  DiscardAddGroupChanges,
  SetActiveDeleteGroupId,
  HandleOnDeleteGroupIconClick,
  ChangeNameFieldFromDrawer,
  ChangeDescriptionFieldFromDrawer,
  ClearAllEditGroupDrawers,
  RefreshEditGroupPage,
  HideNotification,
  RemovingUsersFinished,
  SetRemovedUsersCount,
  ClearRemovedUsersCount,
  CloseMapGroupsErrorBanner,
  CloseGetIdpGroupsErrorBanner,
  CloseErrorBanners,
  FetchSharedGroupRoles,
} from './actions';
import {
  combineWithSelectedOptions,
  extractUsersOptions,
  formatGroupRoles,
  formatGroupUsers,
} from './helpers';
import {
  ICreateGroupParams,
  IRoleAndInstanceIds,
  IUpdateGroupMappingsArgs,
} from './interfaces';
import {
  getActiveDeleteGroupId,
  getActiveRemoveAssignedSingleUserId,
  getActiveRemoveAssignedUserIds,
  getAddGroup,
  getAddGroupUsersSelectedOptions,
  getAllRoles,
  getAssignRolesDrawerTable,
  getEditedGroup,
  getEditedGroupId,
  getEditedGroupState,
  getEditGroupNameDrawer,
  getFormFieldsForNewIdpGroup,
  getGroupRoles,
  getGroupUsers,
  getIsAddGroupDirty,
  getIsAssignRolesDrawerVisible,
  getPristineInstancesOptions,
  getPristineProductsAndRolesOptions,
  getRemovedUsersCount,
  getRemoveRoleById,
  getRemoveUserById,
  getSelectedIntanceIds,
  getSelectedRolesIDs,
  getSelectedUsersOptionsFromDrawer,
  getSharedGroupRoles,
  getStepperMode,
} from './selectors';
import * as Paths from '../../../app/paths';
import { prometheusAPIClient } from '../../api/clients';
import { IActionWithPayload } from '../../core/redux/interfaces';
import {
  GroupsNotificationType,
  RemovedUserMode,
  GroupType,
} from '../../enums/Groups';
import { IBootstrap, IUser } from '../../interfaces/ICommon';
import {
  IAddGroup,
  IDrawerAddGroup,
  IExistingMappings,
  IFetchGroupRolesResponseType,
  IFetchGroupsResponseType,
  IFormattedGroupUser,
  IGroup,
  IGroupFields,
  IGroupRole,
  IGroupUser,
  IGroupUsersResponseType,
  IRemovedUsersCount,
  IRolesRows,
  ISelectedUser,
  ISharedGroupRole,
} from '../../interfaces/IGroups';
import {
  IIdentityProviderData,
  IIdpData,
} from '../../interfaces/IIdentityProvider';
import {
  IRole,
  RbacBundledRolesResponseType,
  RbacCustomRolesResponseType,
} from '../../interfaces/IUserRoles';
import { notifyFormPopulated } from '../../services/formPopulated';
import { getCombinedRoles } from '../../utils/shared';
import { FetchAllProductsInstances, FetchAllUsers } from '../common/actions';
import { filterNonSystemGroups } from '../common/helpers';
import {
  getAllUsers,
  getBootstrapConfig,
  getFeatureFlagsSet,
  getNavigation,
  getUniqueRegions,
} from '../common/selectors';
import { getEnterpriseId } from '../enterprises/selectors';
import { getExternalIdpData, getIdp } from '../idp-management/selectors';
import { fetchProductsList } from '../overview/store/actions';
import { RbacRolesResponseType } from '../users/interfaces';

type AddGroupResponseType = AxiosResponse<IGroup> | any;

type FetchGroupsResponseType = AxiosResponse<IFetchGroupsResponseType> | any;

type FetchGroupRolesResponseType =
  | AxiosResponse<IFetchGroupRolesResponseType>
  | any;

type FetchSharedGroupRolesResponseType = AxiosResponse<ISharedGroupRole> | any;

type FetchGroupUsersResponseType =
  | AxiosResponse<IGroupUsersResponseType[]>
  | any;

type EditedGroupType = { name: string } | any;

export function* handleOnDeleteGroupIconClick(
  action: IActionWithPayload<string>
): Generator {
  const groupId = action.payload;

  yield put(SetActiveDeleteGroupId(groupId));
  yield put(FetchGroupUsers.PENDING());

  yield take(FetchGroupUsers.Success.TYPE);
  yield put(ShowDeleteGroupModal());
}

export function* handleFetchGroups(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const featureFlagsSet = (yield select(getFeatureFlagsSet)) as Set<string>;
  try {
    const response: FetchGroupsResponseType = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/groups`
    );
    const rbacGroups: IGroup[] = response.data.rbacGroups;
    const hasSystemGroupFeatureFlag = featureFlagsSet.has(
      FEATURE_FLAG_CONSTANTS.IAM_SYSTEM_GROUPS_10194
    );
    const filteredGroups = filterNonSystemGroups(rbacGroups);
    yield put(
      FetchAllGroups.SUCCESS(
        hasSystemGroupFeatureFlag ? rbacGroups : filteredGroups
      )
    );
  } catch (error) {
    yield put(FetchAllGroups.ERROR(error as Error));
  }
}

export function* handleAddGroup(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const { basePath, isStandalone } = (yield select(
    getBootstrapConfig
  )) as IBootstrap;
  const { navigateFn } = (yield select(getNavigation)) as any;

  const basepath = isStandalone ? '' : basePath;
  const path = `${basepath}/${Paths.GROUPS}`;
  const addedGroup = (yield select(getAddGroup)) as IAddGroup;
  const usersSelectedOptions = (yield select(
    getAddGroupUsersSelectedOptions
  )) as CDSOption[];
  try {
    const response: AddGroupResponseType = yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/groups`,
      {
        name: addedGroup.name.value,
        description: addedGroup.description.value,
      }
    );
    if (usersSelectedOptions.length > 0) {
      yield put(AssignUsers.PENDING(response.data.id));
      return;
    }
    yield addGroupCallbackFn();
  } catch (error) {
    yield put(AddGroup.ERROR(error as Error));
    yield call(navigateFn, path);
    yield delay(100);
    yield put(AddNotification(GroupsNotificationType.ERROR));
  }
}

export function* addGroupOnIdpStepper(
  action: IActionWithPayload<ICreateGroupParams>
): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const fieldsForIdpGroupCreating = (yield select(
    getFormFieldsForNewIdpGroup
  )) as IAddGroup;

  try {
    const { onSuccess } = action.payload;

    const response: IDrawerAddGroup = yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/groups`,
      {
        name: fieldsForIdpGroupCreating.name.value,
        description: fieldsForIdpGroupCreating.description.value,
      }
    );
    const data = response.data;

    yield put(FetchAllGroups.PENDING());
    yield put(ToggleGroupsDrawerVisibility(false));
    yield call(onSuccess, fieldsForIdpGroupCreating.name.value);

    yield put(CreateGroup.SUCCESS(data));
  } catch (error) {
    yield put(CreateGroup.ERROR(error as Error));
  }
}

export function* addGroupCallbackFn(): Generator {
  const { basePath, isStandalone } = (yield select(
    getBootstrapConfig
  )) as IBootstrap;
  const { navigateFn } = (yield select(getNavigation)) as any;

  const basepath = isStandalone ? '' : basePath;
  const path = `${basepath}/${Paths.GROUPS}`;

  yield put(AddGroup.SUCCESS());
  yield put(DiscardAddGroupChanges(false));

  yield call(navigateFn, path);
  yield delay(100);
  yield put(AddNotification(GroupsNotificationType.CREATE_GROUP));
  yield put(SetAddGroupStep(1));
}

export function* handleAssignUsersToGroup(
  action: IActionWithPayload<string>
): Generator {
  const groupId = action.payload;
  const addedGroup = (yield select(getAddGroup)) as IAddGroup;
  const filteredSelectedUsers = addedGroup.selectedUsersOptions.filter(
    (user) => user.selected
  );

  try {
    yield all(
      filteredSelectedUsers.map((assignedUser: ISelectedUser) =>
        put(AssignUser.PENDING({ groupId, assignedUser }))
      )
    );

    yield addGroupCallbackFn();
  } catch (error) {
    yield put(AssignUsers.ERROR(error as Error));
  }
}

export function* handleFetchGroupUsers(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const editedGroupId = (yield select(getEditedGroupId)) as string;
  const deleteGroupId = (yield select(getActiveDeleteGroupId)) as string;

  const groupId = editedGroupId || deleteGroupId;

  try {
    const response: FetchGroupUsersResponseType = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/groups/${groupId}/users`
    );

    const groupUsers = response.data.rbacUsersByGroup as IGroupUser[];
    const allUsers = (yield select(getAllUsers)) as IUser[];
    const formattedUsers = formatGroupUsers(groupUsers, allUsers);

    yield put(FetchGroupUsers.SUCCESS(formattedUsers));
  } catch (error) {
    yield put(FetchGroupUsers.ERROR(error as Error));
  }
}

export function* handleDeleteUsersFromGroup(): Generator {
  const activeRemoveUserIds = (yield select(
    getActiveRemoveAssignedUserIds
  )) as string[];
  const activeRemoveSingleUserId = (yield select(
    getActiveRemoveAssignedSingleUserId
  )) as string;

  if (!activeRemoveUserIds.length && !activeRemoveSingleUserId) {
    return;
  }

  yield put(ClearRemovedUsersCount());

  if (activeRemoveUserIds.length) {
    yield all(
      activeRemoveUserIds.map((removeUserId: string) =>
        call(handleDeleteSingleUserFromGroup, removeUserId)
      )
    );
  } else {
    yield call(handleDeleteSingleUserFromGroup, activeRemoveSingleUserId);
  }
  yield put(DeleteAssignedGroupUsers.SUCCESS());
  yield put(RemovingUsersFinished());
  yield put(RefreshEditGroupPage('users'));
}

export function* handleDeleteSingleUserFromGroup(
  removeUserId: string
): Generator {
  const removeAssignedUser = (yield select(
    getRemoveUserById,
    removeUserId
  )) as IFormattedGroupUser;

  if (!removeAssignedUser || !removeUserId) {
    return;
  }

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${removeAssignedUser.enterpriseId}/rbac/users/${removeAssignedUser.userId}/groups/${removeAssignedUser.id}`
    );
    yield put(DeleteAssignedSingleGroupUser.SUCCESS());
    yield put(SetRemovedUsersCount(RemovedUserMode.Successful));
    yield put(FetchGroupUsers.PENDING());
  } catch (error) {
    yield put(DeleteAssignedSingleGroupUser.ERROR(error as Error));
    yield put(SetRemovedUsersCount(RemovedUserMode.Failed));
  }
}

export function* handleRemovingUsersFinished(): Generator {
  const removedUsersCount = (yield select(
    getRemovedUsersCount
  )) as IRemovedUsersCount;

  if (!removedUsersCount.failed) {
    yield put(AddNotification(GroupsNotificationType.REMOVE_USERS));
  }
}

export function* handleDeleteGroup(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const groupId = (yield select(getActiveDeleteGroupId)) as string;

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/rbac/groups/${groupId}`
    );
    yield put(AddNotification(GroupsNotificationType.DELETE_GROUP));
    yield take(HideNotification.TYPE);
    yield put(DeleteGroup.SUCCESS());
    yield put(RefreshGroupsPage());
  } catch (error) {
    yield put(DeleteGroup.ERROR(error as Error));
  }
}

export function* handleEditGroupName(): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const editedGroupId = (yield select(getEditedGroupId)) as string;
  const groupFields = (yield select(getEditGroupNameDrawer)) as IGroupFields;
  const {
    data: { type },
  } = (yield select(getEditedGroupState)) as { data: IGroup };

  try {
    yield prometheusAPIClient.put(
      `/api/enterprises/${enterpriseId}/rbac/groups/${editedGroupId}`,
      {
        name: groupFields.name.value,
        description: groupFields.description.value,
        type,
      }
    );
    yield put(EditGroupName.SUCCESS());
    yield put(FetchGroupById.PENDING(editedGroupId));
    yield put(FetchAllGroups.PENDING());

    yield take(FetchGroupById.Success.TYPE);
    yield put(AddNotification(GroupsNotificationType.CHANGE_GROUP_NAME));
  } catch (error) {
    yield put(EditGroupName.ERROR(error as Error));
    yield put(AddNotification(GroupsNotificationType.ERROR));
  }
}

export function* handleFetchUsersSuccess(
  action: IActionWithPayload<IUser[]>
): Generator {
  const { payload } = action;
  const groupUsers = (yield select(getGroupUsers)) as IFormattedGroupUser[];
  const allUsers = (yield select(getAllUsers)) as IUser[];
  const selectedUsersOptions = (yield select(
    getSelectedUsersOptionsFromDrawer
  )) as CDSOption[];
  const updatedUsersOptionsFromDrawer = combineWithSelectedOptions(
    selectedUsersOptions,
    extractUsersOptions(payload, groupUsers)
  );

  yield put(SetUsersOptions(extractUsersOptions(allUsers)));
  yield put(SetUsersOptionsFromDrawer(updatedUsersOptionsFromDrawer));
}

export function* handleFetchRoles(): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const featureFlagsSet = (yield select(getFeatureFlagsSet)) as Set<string>;

  try {
    const response: RbacRolesResponseType = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/roles`
    );

    const customRolesResponse: RbacCustomRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/customRoles`
      );

    const bundledRolesResponse: RbacBundledRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/bundledRoles`
      );

    const allRoles = getCombinedRoles(
      response,
      customRolesResponse,
      featureFlagsSet.has(FEATURE_FLAG_CONSTANTS.BUNDLED_ROLE)
        ? bundledRolesResponse
        : null
    ).filter((role) =>
      new Set(
        role.featureFlags?.map((flag) =>
          _toUpper(flag).replace(FEATURE_FLAG_PREFIX, '')
        )
      ).isSubsetOf(featureFlagsSet)
    );

    yield put(FetchAllRoles.SUCCESS(allRoles));
  } catch (error) {
    yield put(FetchAllRoles.ERROR(error as Error));
  }
}

export function* handleFetchSharedGroupRoles(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const groupId = (yield select(getEditedGroupId)) as string;

  try {
    const sharedRolesResponse: FetchSharedGroupRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/sharedGroups/${groupId}`
      );
    const sharedRoles: ISharedGroupRole = sharedRolesResponse.data;

    yield put(FetchSharedGroupRoles.SUCCESS(sharedRoles));
  } catch (error) {
    yield put(FetchSharedGroupRoles.ERROR(error as Error));
  }
}

export function* handleFetchGroupRoles(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const groupId = (yield select(getEditedGroupId)) as string;
  const allRoles = (yield select(getAllRoles)) as IRole[];
  const sharedGroupRoles = (yield select(
    getSharedGroupRoles
  )) as ISharedGroupRole;

  try {
    const response: FetchGroupRolesResponseType = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/groups/${groupId}/roles`
    );
    const groupRoles: IGroupRole[] = response.data.rbacGroupRoles;
    const sortedRoles = formatGroupRoles(
      groupRoles,
      allRoles,
      sharedGroupRoles
    );

    yield put(FetchGroupRoles.SUCCESS(sortedRoles));
  } catch (error) {
    yield put(FetchGroupRoles.ERROR(error as Error));
  }
}

export function* addUsersCallbackFn(): Generator {
  yield put(AssignUsers.SUCCESS());

  yield put(HideAddUsersDrawer());
  yield put(AddNotification(GroupsNotificationType.UPDATE_GROUP));

  yield put(RefreshEditGroupPage('users'));
}

export function* handleAddUsersToGroup(): Generator {
  const groupId = (yield select(getEditedGroupId)) as string;
  const selectedUsersOptionsFromDrawer = (yield select(
    getSelectedUsersOptionsFromDrawer
  )) as ISelectedUser[];

  try {
    yield all(
      selectedUsersOptionsFromDrawer.map(
        (assignedUser: ISelectedUser, index: number) => {
          const callbackFnArg =
            index === selectedUsersOptionsFromDrawer.length - 1
              ? addUsersCallbackFn
              : null;
          return put(
            AssignUser.PENDING({
              groupId,
              assignedUser,
              callbackFn: callbackFnArg,
            })
          );
        }
      )
    );
  } catch (error) {
    yield put(AssignUsers.ERROR(error as Error));
  }
}

export function* handleAssignUserToGroup(
  action: IActionWithPayload<{
    groupId: string;
    assignedUser: ISelectedUser;
    callbackFn?: Function | null;
  }>
): Generator {
  const { assignedUser, groupId, callbackFn } = action.payload;
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/users/${assignedUser.id}/groups`,
      {
        groupId,
      }
    );
    yield put(AssignUser.SUCCESS());

    // -- is last item and has callbackFn
    if (callbackFn) {
      yield callbackFn();
    }
  } catch (error) {
    yield put(AssignUser.ERROR(error as Error));
  }
}

export function* assignRolesCallbackFn(): Generator {
  yield put(AssignRolesToGroup.SUCCESS());

  yield put(AddNotification(GroupsNotificationType.ASSIGN_ROLES));
  yield put(HideAssignRolesDrawer());

  yield put(RefreshEditGroupPage('roles'));
}

export function* handleAssignRolesToGroup(): Generator {
  const rolesIDs = (yield select(getSelectedRolesIDs)) as string[];

  try {
    yield all(
      rolesIDs.map((id: string, index: number) => {
        const callbackFnArg =
          index === rolesIDs.length - 1 ? assignRolesCallbackFn : null;
        return put(
          AssignRoleToGroup.PENDING({ id, callbackFn: callbackFnArg })
        );
      })
    );
  } catch (error) {
    yield put(AssignRolesToGroup.ERROR(error as Error));
  }
}

export function* handleAssignRoleToGroup(
  action: IActionWithPayload<{
    id: string;
    callbackFn: Function | null;
  }>
): Generator {
  const {
    payload: { id: roleId, callbackFn },
  } = action;

  const enterpriseId = yield select(getEnterpriseId);
  const editedGroupId = yield select(getEditedGroupId);

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/groups/${editedGroupId}/roles`,
      {
        roleId,
        enterpriseId,
      }
    );
    yield put(AssignRoleToGroup.SUCCESS());

    // -- is last item and has callbackFn
    if (callbackFn) {
      yield callbackFn();
    }
  } catch (error) {
    yield put(AssignRoleToGroup.ERROR(error as Error));
  }
}

export function* handleRefreshEditGroupPage(
  action: IActionWithPayload<string>
): Generator {
  const { payload: target } = action;

  yield put(fetchProductsList());

  if (target === 'users') {
    // -- Fetch Groups and Users
    yield put(FetchAllUsers.PENDING());
    yield put(FetchGroupUsers.PENDING());
  }

  if (target === 'roles') {
    // -- Fetch All Groups and Group Roles
    yield put(FetchAllGroups.PENDING());
    yield put(FetchGroupRoles.PENDING());
    yield put(FetchSharedGroupRoles.PENDING());
    yield put(FetchAllRoles.PENDING());
    yield take(HideNotification.TYPE);
    yield put(
      SetDrawerRolesTable([
        {
          selectedProduct: null,
          productsOptions: [],
          rolesOptions: {},
        },
      ])
    );
    yield put(
      SetAssignRolesTableDrawer([
        {
          selectedRole: null,
          rolesOptions: [],
          instancesOptions: {},
        },
      ])
    );
  }
  yield take(HideNotification.TYPE);
  yield put(ClearAllEditGroupDrawers());
}

export function* handleRefreshGroupsPage(): Generator {
  yield put(FetchAllGroups.PENDING());
}

export function* handleRemoveAssignedRoleFromGroup(): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const removedRole = (yield select(getRemoveRoleById)) as IGroupRole;
  const editedGroupId = yield select(getEditedGroupId);

  if (!removedRole) {
    return;
  }

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/rbac/groups/${editedGroupId}/roles/${removedRole.id}`
    );
    yield put(DeleteAssignedGroupRole.SUCCESS());
    yield put(AddNotification(GroupsNotificationType.REMOVE_ROLES));

    yield put(RefreshEditGroupPage('roles'));
  } catch (error) {
    yield put(DeleteAssignedGroupRole.ERROR(error as Error));
  }
}

export function* handleShowEditGroupDrawer(): Generator {
  const groupName: EditedGroupType = yield select(getEditedGroup);
  yield put(
    ChangeNameFieldFromDrawer({ value: groupName?.name, touched: false })
  );
  yield put(
    ChangeDescriptionFieldFromDrawer({
      value: groupName?.description,
      touched: false,
    })
  );
}

export function* handleChangeAddGroupInputs(): Generator {
  const isDirty = (yield select(getIsAddGroupDirty)) as boolean;

  yield delay(500);
  yield call(notifyFormPopulated, { isDirty });
}

export function* handleFetchGroupById(
  action: ReturnType<typeof FetchGroupById.PENDING>
): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const groupId = action.payload;

    const response: FetchGroupsResponseType = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/groups/${groupId}`
    );

    yield put(FetchGroupById.SUCCESS(response.data));
  } catch (error) {
    yield put(FetchGroupById.ERROR(error as Error));
  }
}

export function* handleToggleAssignRolesDrawerVisibility(): Generator {
  const isAssignRolesDrawerVisible = yield select(
    getIsAssignRolesDrawerVisible
  );
  const assignRolesTable = (yield select(
    getAssignRolesDrawerTable
  )) as IRolesRows[];
  const pristineProductsAndRolesOptions = yield select(
    getPristineProductsAndRolesOptions
  );
  const pristineInstancesOptions = yield select(getPristineInstancesOptions);

  if (isAssignRolesDrawerVisible && assignRolesTable.length) {
    const payload = assignRolesTable.map((item: IRolesRows) => ({
      ...item,
      selectedRole: null,
      selectedProduct: null,
      rolesOptions: pristineProductsAndRolesOptions,
      instancesOptions: pristineInstancesOptions,
    }));

    yield put(SetAssignRolesTableDrawer(payload));
  }
}

export function* handleFetchAllProductsInstances(): Generator {
  const groupRoles: IGroupRole[] = (yield select(
    getGroupRoles
  )) as IGroupRole[];
  const uniqueRegions: CDSOption[] = (yield select(
    getUniqueRegions
  )) as CDSOption[];

  if (uniqueRegions.length && groupRoles.length) {
    yield put(SetRegionFilterOptions(uniqueRegions));
  }
}

export function* handleUpdateGroupMappings(
  action: IActionWithPayload<IUpdateGroupMappingsArgs>
): Generator {
  const { groupMappings, onRequestEnd } = action.payload;

  const { basePath, isStandalone } = (yield select(
    getBootstrapConfig
  )) as IBootstrap;
  const { navigateFn } = (yield select(getNavigation)) as any;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const idps = (yield select(getIdp)) as IIdentityProviderData[];
  const stepperMode = (yield select(getStepperMode)) as string;

  const basepath = isStandalone ? '' : basePath;
  const path = `${basepath}/${Paths.GROUPS}`;

  try {
    if (!idps.length) throw new Error();

    yield prometheusAPIClient.put(
      `/api/enterprises/${enterpriseId}/idps/${idps[0].id}/groupMappings`,
      {
        groupMappings,
      }
    );

    yield delay(100);
    yield put(UpdateGroupMappings.SUCCESS());

    const notificationType =
      stepperMode === 'create'
        ? GroupsNotificationType.CREATE_GROUP_MAPPINGS
        : GroupsNotificationType.UPDATE_GROUP_MAPPINGS;
    yield put(AddNotification(notificationType));
    yield put(FetchGroupMappings.PENDING());
  } catch (error) {
    yield put(UpdateGroupMappings.ERROR(error as AxiosError));
  } finally {
    onRequestEnd();
    yield call(navigateFn, path);
    // NOTE: "notifyFormPopulated" action is called to notify shell app
    // that banner should be closed after user leaves groups page.
    // Only with "isDirty: true" param the "routeChanged" event is triggered.
    yield call(notifyFormPopulated, { isDirty: true });
  }
}

export function* handleFetchGroupMappings(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const idps = (yield select(getIdp)) as IIdentityProviderData[];

  try {
    if (!idps.length) throw new Error();

    const response = yield prometheusAPIClient.get<IExistingMappings['data']>(
      `/api/enterprises/${enterpriseId}/idps/${idps[0].id}/groupMappings`
    );

    const { data } = response as AxiosResponse<IExistingMappings['data']>;

    yield put(FetchGroupMappings.SUCCESS(data));
  } catch (error) {
    yield put(FetchGroupMappings.ERROR(error as Error));
  }
}

export function* handleAssignSelectedInstances(): Generator {
  const selectedIntanceIds = (yield select(
    getSelectedIntanceIds
  )) as IRoleAndInstanceIds[];

  try {
    yield all(
      selectedIntanceIds.map(
        (roleAndInstanceIds: IRoleAndInstanceIds, index: number) => {
          const callbackFnArg =
            index === selectedIntanceIds.length - 1
              ? assignRolesCallbackFn
              : null;
          return put(
            AssignSelectedInstance.PENDING({
              roleAndInstanceIds,
              callbackFn: callbackFnArg,
            })
          );
        }
      )
    );
  } catch (error) {
    yield put(AssignSelectedInstances.ERROR(error as Error));
    yield put(AddNotification(GroupsNotificationType.ERROR));
  }
}

export function* handleAssignSelectedInstance(
  action: IActionWithPayload<{
    roleAndInstanceIds: IRoleAndInstanceIds;
    callbackFn: Function | null;
  }>
): Generator {
  const {
    payload: { roleAndInstanceIds, callbackFn },
  } = action;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const groupId = (yield select(getEditedGroupId)) as string;

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/groups/${groupId}/roles`,
      {
        roleId: roleAndInstanceIds.roleId,
        tenantId: roleAndInstanceIds.instanceId,
      }
    );

    yield put(AssignSelectedInstance.SUCCESS());

    // -- is last item and has callbackFn
    if (callbackFn) {
      yield callbackFn();
    }
  } catch (error) {
    yield put(AssignSelectedInstance.ERROR(error as Error));
  }
}

export function* handleFetchIdpGroups(): Generator {
  const idps = (yield select(getIdp)) as IIdentityProviderData[];
  const externalIdps = (yield select(getExternalIdpData)) as IIdpData[];
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  if (!idps?.length && !externalIdps?.length) {
    return;
  }

  try {
    const idpId = idps[0]?.id || externalIdps[0]?.id;

    const response = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/idps/${idpId}/groupMappings/idpGroups`
    );

    const { data } = response as AxiosResponse<{ rbacGroups: IGroup[] }>;
    const idpGroups = data.rbacGroups;
    const filteredIdpGroups = idpGroups.filter(
      (idpgroup: { type: GroupType }) => idpgroup.type !== GroupType.System
    );
    yield put(FetchIdpGroups.SUCCESS(filteredIdpGroups));
  } catch (error) {
    yield put(FetchIdpGroups.ERROR(error as Error));
    yield put(AddNotification(GroupsNotificationType.ERROR));
  }
}

export function* handleCloseErrorBanners(): Generator {
  yield put(CloseMapGroupsErrorBanner());
  yield put(CloseGetIdpGroupsErrorBanner());
}

export function* groupsSaga(): Generator {
  yield takeLatest(FetchAllRoles.Pending.TYPE, handleFetchRoles);
  yield takeEvery(
    HandleOnDeleteGroupIconClick.TYPE,
    handleOnDeleteGroupIconClick
  );
  yield takeEvery(ShowEditGroupNameDrawer.TYPE, handleShowEditGroupDrawer);
  yield takeLatest(
    FetchSharedGroupRoles.Pending.TYPE,
    handleFetchSharedGroupRoles
  );
  yield takeLatest(FetchGroupRoles.Pending.TYPE, handleFetchGroupRoles);
  yield takeLatest(UpdateGroupMappings.Pending.TYPE, handleUpdateGroupMappings);
  yield takeLatest(FetchGroupMappings.Pending.TYPE, handleFetchGroupMappings);
  yield takeLatest(FetchIdpGroups.Pending.TYPE, handleFetchIdpGroups);
  yield takeLatest(FetchAllUsers.Success.TYPE, handleFetchUsersSuccess);
  yield takeLatest(CreateGroup.Pending.TYPE, addGroupOnIdpStepper);
  yield takeLatest(FetchAllGroups.Pending.TYPE, handleFetchGroups);
  yield takeLatest(AddGroup.Pending.TYPE, handleAddGroup);
  yield takeLatest(AssignUsers.Pending.TYPE, handleAssignUsersToGroup);
  yield takeEvery(AssignUser.Pending.TYPE, handleAssignUserToGroup);
  yield takeEvery(FetchGroupById.Pending.TYPE, handleFetchGroupById);

  // Here should be "takeEvery" for avoid issue with quick double ckick on remove button in modal
  yield takeEvery(
    DeleteAssignedGroupUsers.Pending.TYPE,
    handleDeleteUsersFromGroup
  );
  yield takeLatest(FetchGroupUsers.Pending.TYPE, handleFetchGroupUsers);
  yield takeLatest(DeleteGroup.Pending.TYPE, handleDeleteGroup);
  yield takeLatest(EditGroupName.Pending.TYPE, handleEditGroupName);
  yield takeLatest(AssignRolesToGroup.Pending.TYPE, handleAssignRolesToGroup);
  yield takeEvery(AssignRoleToGroup.Pending.TYPE, handleAssignRoleToGroup);

  // Here should be "takeEvery" for avoid issue with quick double ckick on remove button in modal
  yield takeEvery(
    DeleteAssignedGroupRole.Pending.TYPE,
    handleRemoveAssignedRoleFromGroup
  );
  yield takeLatest(AddUsersToGroup.TYPE, handleAddUsersToGroup);
  yield takeLatest(RefreshEditGroupPage.TYPE, handleRefreshEditGroupPage);
  yield takeLatest(
    [ChangeNameField.TYPE, ChangeDescriptionField.TYPE],
    handleChangeAddGroupInputs
  );
  yield takeLatest(RefreshGroupsPage.TYPE, handleRefreshGroupsPage);
  yield takeLatest(
    [FetchAllProductsInstances.Success.TYPE, FetchGroupRoles.Success.TYPE],
    handleFetchAllProductsInstances
  );
  yield takeEvery(
    ToggleAssignRolesDrawerVisibility.TYPE,
    handleToggleAssignRolesDrawerVisibility
  );
  yield takeLatest(
    AssignSelectedInstances.Pending.TYPE,
    handleAssignSelectedInstances
  );
  yield takeLatest(
    AssignSelectedInstance.Pending.TYPE,
    handleAssignSelectedInstance
  );
  yield takeLatest(RemovingUsersFinished.TYPE, handleRemovingUsersFinished);

  yield takeLatest(CloseErrorBanners.TYPE, handleCloseErrorBanners);
}
